import React from 'react';
import type { ReactNode } from 'react';
import type { AdminVocab, AdminMaterial } from '../../types';
import MaterialIndex from './MaterialIndex';
import { Link } from 'react-router-dom';
import { formatMaterialSentence } from '../../common';

const VocabsIndex: React.FC = () => {
  const getVocabRow = (material: AdminMaterial, filterString: string): ReactNode => {
    const vocab = material as AdminVocab;
    return (
      <>
        <td>{vocab.id}</td>
        <td>{vocab.level}</td>
        <td style={{ whiteSpace: 'nowrap' }}>
          {vocab.kanji}
        </td>
        <td style={{ whiteSpace: 'nowrap' }}>
          {vocab.reading}
        </td>
        <td style={{ whiteSpace: 'nowrap' }}>
          {vocab.definition}
        </td>
        <td>
          <span dangerouslySetInnerHTML={{ __html: formatMaterialSentence(vocab.flashcard_material, vocab.level, true) }} />
          <br/>
          {vocab.flashcard_material.sentence_meaning}
        </td>
        <td>
          {!!vocab.flashcard_material.image_url && (
            <img width="200px" src={vocab.flashcard_material.image_url} />
          )}
        </td>
        <td>
          <Link to={`/admin/vocabs/${vocab.id}?filters=${filterString}`} className="btn btn-outline-secondary btn-sm" target="_blank">View</Link>
        </td>
      </>
    );
  };

  return (
    <div className="container">
      <MaterialIndex materialType={'Vocab'} getMaterialRow={getVocabRow} />
    </div>
  );
};

export default VocabsIndex;
