import type {
  UserSettings,
  SentenceBreakdownPart,
  Stat,
  SentenceBreakdown,
  FlashcardMaterial
} from './types';
import { Course } from './types';

export const highlightKana = (kana: string, word: string): string => {
  return word.replace(new RegExp(`(${kana})`, 'g'), '<span class="word-highlight">$1</span>');
};

export const formatBreakdown = (sentenceBreakdown: SentenceBreakdown | undefined): string => {
  if (!sentenceBreakdown) {
    return '';
  }

  try {
    return sentenceBreakdown.breakdown.filter(validBreakdownPart).map((bd: SentenceBreakdownPart) => `- ${bd.word} (${bd.pronunciation}): ${bd.meaning}`).join('\n');
  } catch (err: any) {
    console.log(`error in formatBreakdown: ${JSON.stringify(sentenceBreakdown)} - ${err.message}`);
  }

  return '';
};

export const validBreakdownPart = (sbp: SentenceBreakdownPart): boolean => {
  if (['、', '。', 'は', 'を', 'が', 'に', 'の', 'で', 'と', 'へ', 'な'].includes(sbp.word)) {
    return false;
  }
  return true;
};

export const colorClassFromLevel = (level: number): string => {
  return {
    1: 'bg-white-belt text-dark',
    2: 'bg-yellow text-dark',
    3: 'bg-orange text-light',
    4: 'bg-purple text-light',
    5: 'bg-green text-light',
    6: 'bg-blue text-light',
    7: 'bg-brown text-light',
    8: 'bg-red text-light',
    9: 'bg-black text-light',
  }[level] ?? 'bg-white-belt text-dark';
};

export const kanjiString = (level: number): string => {
  return {
    1: '一七三上下中九二五人休先入八六円出力十千口右名四土夕大天女子字学小山川左年手文日早月木本村林校森正気水火犬玉王生田男町白百目石空立竹糸耳花草虫見貝赤足車金雨青音',
    2: '万丸交京今会体何作元兄光公内冬刀分切前北午半南原友古台合同回図国園地場声売夏外多夜太妹姉室家寺少岩工市帰広店弓引弟弱強当形後心思戸才教数新方明星春昼時晴曜書朝来東楽歌止歩母毎毛池汽活海点父牛理用画番直矢知社秋科答算米紙細組絵線羽考聞肉自船色茶行西親角言計記話語読谷買走近通週道遠里野長門間雪雲電頭顔風食首馬高魚鳥鳴麦黄黒',
    3: '丁世両主乗予事仕他代住使係倍全具写列助勉動勝化区医去反取受号向君味命和品員商問坂央始委守安定実客宮宿寒対局屋岸島州帳平幸度庫庭式役待急息悪悲想意感所打投拾持指放整旅族昔昭暑暗曲有服期板柱根植業様横橋次歯死氷決油波注泳洋流消深温港湖湯漢炭物球由申界畑病発登皮皿相県真着短研礼神祭福秒究章童笛第筆等箱級終緑練羊美習者育苦荷落葉薬血表詩調談豆負起路身転軽農返追送速進遊運部都配酒重鉄銀開院陽階集面題飲館駅鼻',
    4: '不争付令以仲伝位低例便信倉候借停健側働億兆児共兵典冷初別利刷副功加努労勇包卒協単博印参史司各告周唱喜器囲固型堂塩士変夫失好季孫完官害察巣差希席帯底府康建径徒得必念愛成戦折挙改救敗散料旗昨景最望未末札材束松果栄案梅械極標機欠歴残殺毒氏民求治法泣浅浴清満漁灯無然焼照熱牧特産的省祝票種積競笑管節粉紀約結給続置老胃脈腸臣航良芸芽英菜街衣要覚観訓試説課議象貨貯費賞軍輪辞辺連達選郡量録鏡関陸隊静順願類飛飯養験',
    5: '久仏仮件任似余価保修俵個備像再刊判制券則効務勢厚句可営因団圧在均基報境墓増夢妻婦容寄富導居属布師常幹序弁張往復徳志応快性恩情態慣承技招授採接提損支政故敵断旧易暴条枝査格桜検構武比永河液混減測準演潔災燃版犯状独率現留略益眼破確示祖禁移程税築精素経統絶綿総編績織罪群義耕職肥能興舌舎術衛製複規解設許証評講謝識護豊財貧責貸貿賀資賛質輸述迷退逆造過適酸鉱銅銭防限険際雑非預領額飼',
    6: '並乱乳亡仁供俳値傷優党冊処刻割創劇勤危卵厳収后否吸呼善困垂城域奏奮姿存孝宅宇宗宙宝宣密寸専射将尊就尺届展層己巻幕干幼庁座延律従忘忠憲我批担拝拡捨探推揮操敬映晩暖暮朗机枚染株棒模権樹欲段沿泉洗派済源潮激灰熟片班異疑痛皇盛盟看砂磁私秘穀穴窓筋策簡糖系紅納純絹縦縮署翌聖肺背胸脳腹臓臨至若著蒸蔵蚕衆裁装裏補視覧討訪訳詞誌認誕誠誤論諸警貴賃遺郵郷針鋼閉閣降陛除障難革頂骨',
    7: '丈与丘丹乾互井介仰伺依侵俗倒偉傍傾僧儀兼冒凡凶刈到刺剣剤劣勧匹占即却及叫召吐含吹咲唐嘆噴圏坊執堅堤塔壁壊壱奇奥奴妙姓威娘婚寂寝尋尽尾屈峠峰巡巨帽幅幾床弐弾彩影彼征御微徴忙怒怖恋恐恒恥恵悩惑惨慎慢慮憶戒戯扇払扱抗抜抱抵押拍拓拠振捕掘描握援搬摘撃攻敏敷斜旨旬是普暇暦曇更替朱朽杯枯柄柔桃欄歓歳殖殿汗汚沈沖沢沼況泊浜浮浸涙淡添渡溶滴漫澄濁濃為烈煙煮燥爆狂狩狭猛獣獲玄珍環甘畳疲療皆盆盗監盤盾眠瞬矛砲祈秀称稲稿突端箇範粒紋紫紹絡継維網緯縁繁繰罰翼耐肩肪胴脂脚脱腐腕腰膚致舗舞舟般芋芝茂荒菓蓄薄薪被襲触訴詰詳誇誉謡豪販賦贈越趣距跡跳踊踏躍軒較載輝輩込迎迫逃透途遅違遣避郎釈鈍鉛鋭鎖鑑闘陣陰隠隣隷雄雅雌離雷需震霧露響項頼飾香駆騒驚髪鬼鮮麗黙鼓齢',
    8: '乏乙了企伏伐伴伸佳侍促倣倹偶催債克免冗冠凍凝刑削励勘募匠匿卑卓卸厘又双吉吏哀哲啓喚喫嘱坑埋塊塗墜墨墳墾壇奉契奪如妨姫娯婆婿嫁嬢孔孤宴審寿封尿岐岳峡崩巧帆帝幻幽廉廊弧彫徐忌怠怪恨悔悟悦惜愚慈慌慕慨慰憂憎憩房抑択抽拘掃掌排掛控措掲揚換揺携搾摂撮擁擦敢斗斤斥施既昇晶暫架某桑棄棋楼概欧欺殊殴没泌浪湾湿滅滑滝滞漂漏潜潤濫瀬炉炊炎焦牲犠猟獄甲畔畜疾痘癖硬碑礎祉稚穂穏穫窒符篤簿籍粋粗粘糧紛紺絞綱緊締緩縛縫繕翻聴肝胆胎胞脅脹膜膨芳苗菊華葬藩虐虚蛮衝衰袋裂裸覆訂託詠該誘請諮諾謀譲豚貫賊賢赦赴超軌軸辛辱逮遂遇遭遵邦邪郊郭酔酵鋳錠錬錯鍛鎮鐘閲阻陪陳陵陶隆随隔隻雇零霊顧飽餓駐騎髄魂魅魔鯨鶏',
    9: '且丙亜享亭仙伯但佐併侮侯俊俸倫偏偵偽傑傘僕僚儒償充准凸凹刃剖剛剰劾勅勲勺匁升厄叔叙吟呈呉唆唇唯喝喪嗣嚇囚坪垣培堀堕堪塀塁塑塚塾壌壮奔奨妃妄妊妥姻娠媒嫌嫡宜宰宵寛寡寧寮尉尚尼履屯岬崇崎帥幣庶庸廃廷弊弔弦彰循徹忍恭悠患悼惰愁愉慶憤憾懇懐懲懸戻扉扶抄把披抹拐拒拙括拷挑挟挿捜据搭摩撤撲擬斉斎旋昆暁曹朕朴杉析枠枢柳栓核栽桟棚棟棺槽款殉殻汁江沸泡泥泰洞津洪浄浦涯涼淑渇渉渋渓渦溝滋漆漠漬漸潟濯煩爵猫献猶猿珠琴璽瓶甚畝疎疫症痢痴癒盲眺睡督矯砕硝硫碁磨礁祥禅禍租秩稼窃窮窯竜筒粛粧糾紡索累紳緒縄繊繭缶罷羅翁耗肌肖肢肯臭舶艇艦茎荘菌薦薫藻虜虞蚊蛇蛍融衡衷裕褐褒襟覇訟診詐詔誓諭謁謄謙謹譜貞貢賄賓賜賠購践軟轄迅迭逐逓逝逸遍遮遷還邸酌酢酪酬酷醜醸釣鈴鉢銃銑銘錘閑閥附陥隅雰霜靴韻頑頒頻顕飢駄騰麻',
  }[level] ?? '';
};

export const isLevelUntrained = (stat: Stat): boolean => {
  return stat && stat.learning === 0 && stat.intermediate === 0 && stat.mastered === 0;
};

export const surroundWord = (sentence: string, list: string[], wordToSurround: string, surroundWith: string[]): string => {
  // Sort list by length in descending order to prioritize longer substrings
  const sortedList = list.filter((w: string) => w.length > wordToSurround.length).sort((a, b) => b.length - a.length);

  // Iterate through the sentence and replace only the target word when it's not part of a longer word
  let result = '';
  let i = 0;

  while (i < sentence.length) {
    // Check if any of the longer words in the list match the sentence at the current position
    let matchedLongerWord = false;
    for (const longerWord of sortedList) {
      if (longerWord !== wordToSurround && sentence.substr(i, longerWord.length) === longerWord) {
        // If we find a longer word, add it to the result as is and skip its length
        result += longerWord;
        i += longerWord.length;
        matchedLongerWord = true;
        break;
      }
    }

    // If no longer word matched, check if the wordToSurround is here and wrap it with <span>
    if (!matchedLongerWord && sentence.substr(i, wordToSurround.length) === wordToSurround) {
      result += `${surroundWith[0]}${wordToSurround}${surroundWith[1]}`;
      i += wordToSurround.length;
    } else if (!matchedLongerWord) {
      // If no word matched, just add the current character
      result += sentence[i];
      i++;
    }
  }

  return result;
}

export const isHiragana = (char: string): boolean => {
  // Ensure it's a single character string
  if (char.length !== 1) {
    return false;
  }
  // Check if the character is in the Hiragana Unicode range
  const code = char.charCodeAt(0);
  return code >= 0x3040 && code <= 0x309F;
}

export const isKatakana = (char: string): boolean => {
  // Ensure it's a single character string
  if (char.length !== 1) {
    return false;
  }
  // Check if the character is in the Hiragana Unicode range
  const code = char.charCodeAt(0);
  return code >= 0x30A0 && code <= 0x30FF;
}

const isEasierKanjiLevel = (kanji: string, level: number): boolean => {
  if (level === 1) {
    return false;
  }
  for (let l = level - 1; l >= 1; l--) {
    if (kanjiString(l).includes(kanji)) {
      return true;
    }
  }
  return false;
};

const doesWordHaveSameOrHarderLevelKanji = (word: string, level: number): boolean => {
  return word.split('').some((character: string) => !isHiragana(character) && !isEasierKanjiLevel(character, level));
};

const parseFurigana = (kanjiWord: string, hiraganaWord: string): { kanji: string, furigana: string, hiraganaSuffix: string } => {
  let kanjiIndex = kanjiWord.length - 1;
  let hiraganaIndex = hiraganaWord.length - 1;

  // Start comparing from the end of both strings
  while (kanjiIndex >= 0 && hiraganaIndex >= 0) {
    if (kanjiWord[kanjiIndex] !== hiraganaWord[hiraganaIndex]) {
      break;
    }
    kanjiIndex--;
    hiraganaIndex--;
  }

  // Return the hiragana portion before the part that matches the kanji
  return { kanji: kanjiWord.slice(0, kanjiIndex + 1), furigana: hiraganaWord.slice(0, hiraganaIndex + 1), hiraganaSuffix: hiraganaWord.slice(hiraganaIndex + 1) };
};

export const formattedSentence = (material: FlashcardMaterial, level: number, flipped: boolean): string => {
  try {
    if (!material.sentence_breakdown) {
      throw new Error('flashcard.sentence_breakdown missing');
    }

    // add highlight
    const breakdownParts: SentenceBreakdownPart[] = material.sentence_breakdown.breakdown;
    const vocab: string = breakdownParts.find((bp: SentenceBreakdownPart) => bp.vocab)?.word ?? material.vocab;
    const wordList: string[] = breakdownParts.map((bp: SentenceBreakdownPart) => bp.word);
    let sentence = surroundWord(material.sentence, wordList, vocab, ['<span class="word-highlight">', '</span>']);

    // add furigana
    sentence = sentenceWithFurigana({ sentence, material, level, all: flipped });

    // add period
    if (!['。', '！', '!'].includes(sentence.slice(-1))) {
      return `${sentence}。`;
    }
    return sentence;
  } catch (err: any) {
    console.log(`error in formattedSentence: ${material.sentence} - ${err.message}`);
  }
  return '';
};

export const formatMaterialSentence = (material: FlashcardMaterial, level: number, flipped: boolean): string => {
  if (material.furigana === 'none') {
    return formattedSentence(material, level, flipped)
  }

  const customVocab = material.sentence_breakdown?.breakdown?.find((item: any) => item.vocab)?.word;
  return formatVocabSentence(material.sentence, customVocab ?? material.vocab);
}

export const formatVocabSentence = (sentence: string, vocab: string): string => {
  try {
    // add furigana
    let formattedSentence = sentence;
    formattedSentence = formattedSentence.replace(/^([^\[ ]+)\[([^\]]+)\]/, '<ruby>$1<rt>$2</rt></ruby>');
    formattedSentence = formattedSentence.replace(/ ([^\[]+)\[([^\]]+)\]/g, '<ruby>$1<rt>$2</rt></ruby>');

    // add highlight
    let startIndex;
    let endIndex;
    let wi = 0;
    let si = 0;
    let startIgnore = false;
    let inRubyTag = false;
    let wordFound = false;
    while (si < formattedSentence.length) {
      // console.log({ letter: formattedSentence.substr(si, 1), wi, startIgnore, inRubyTag, wordFound });
      if (startIgnore) {
        if (formattedSentence.substr(si, 7) === '</ruby>') {
          if (wordFound) {
            endIndex = si + 7;
            break;
          }
          inRubyTag = false;
          startIgnore = false;
          si += 6;
        }
      } else if (formattedSentence.substr(si, 4) === '<rt>') {
        startIgnore = true;
        si += 3;
      } else if (formattedSentence.substr(si, 6) === '<ruby>') {
        inRubyTag = true;
        si += 5;
      } else if (!wordFound && formattedSentence.substr(si, 1) === vocab.substr(wi, 1)) {
        if (wi === 0) {
          if (si >= 6 && formattedSentence.substr(si - 6, 6) === '<ruby>') {
            startIndex = si - 6;
            inRubyTag = true;
          } else {
            startIndex = si;
            inRubyTag = false;
          }
        }
        if (wi === vocab.length - 1) {
          if (inRubyTag) {
            wordFound = true;
          } else {
            endIndex = si + 1;
            break;
          }
        }
        wi++;
      } else {
        wi = 0;
        startIndex = undefined;
      }
      si++;
    }

    if ((startIndex || startIndex === 0) && (endIndex || endIndex === 0)) {
      formattedSentence = formattedSentence.slice(0, endIndex) + '</span>' + formattedSentence.slice(endIndex);
      formattedSentence = formattedSentence.slice(0, startIndex) + '<span class="word-highlight">' + formattedSentence.slice(startIndex);
    }

    // add period
    if (!['。', '！', '!'].includes(sentence.slice(-1))) {
      return `${formattedSentence}。`;
    }
    return formattedSentence;
  } catch (err: any) {
    console.log(`error in formatVocabSentence: ${sentence} - ${err.message}`);
  }
  return '';
};

const sentenceWithFurigana = ({ sentence, material, level, all = false }: { sentence: string, material: FlashcardMaterial, level: number, all: boolean }): string => {
  if (!material.sentence_breakdown) {
    return sentence;
  }

  let _sentence = `${sentence}`;
  const words = [...material.sentence_breakdown.breakdown].sort((a: SentenceBreakdownPart, b: SentenceBreakdownPart) => b.word.length - a.word.length);
  const usedWords: string[] = [];
  for (const word of words) {
    if (usedWords.includes(`${word.word}-${word.pronunciation}`)) {
      continue;
    }
    if (word.word === word.pronunciation) {
      continue;
    }
    if (word.word === 'は' && word.pronunciation === 'わ') {
      continue;
    }
    if (!word.pronunciation) {
      continue;
    }
    if (!sentence.includes(word.word)) {
      continue;
    }
    if (word.word.split('').every((char: string) => isKatakana(char))) {
      continue;
    }

    let showFurigana = false;
    if (all) {
      showFurigana = true;
    } else if (word.word.includes(material.vocab) || material.vocab.includes(word.word) || word.vocab) {
      showFurigana = false;
    } else if (doesWordHaveSameOrHarderLevelKanji(word.word, level)) {
      showFurigana = true;
    }

    const { kanji: kanjiPortion, furigana, hiraganaSuffix } = parseFurigana(word.word, word.pronunciation);
    _sentence = _sentence.replace(new RegExp(`(?<!<ruby[^>]*?>[^<]*?)${word.word}(?![^>]*?</ruby>)`, ''), `<ruby>${kanjiPortion}<rt class="${showFurigana ? 'visible-weak' : 'invisible'}">${furigana}</rt></ruby>${hiraganaSuffix}`)

    usedWords.push(`${word.word}-${word.pronunciation}`);
  }
  return _sentence;
};

// this function should be passed to useEffect
export const loadTooltips = (): (() => void) => {
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  const tooltipList = tooltipTriggerList.map((tooltipTriggerEl) => {
    // @ts-expect-error bootstrap is already loaded
    return new bootstrap.Tooltip(tooltipTriggerEl, {
      placement: 'bottom',
      fallbackPlacements: [], // Prevent automatic placement adjustment
      boundary: 'viewport', // Ensure the tooltip stays within the viewport
    });
  });

  // Cleanup tooltips on component unmount
  return () => {
    tooltipList.forEach((tooltip) => tooltip.dispose());
  };
};

export const pluralize = (word: string, num: number): string => {
  return num === 1 ? `${num} ${word}` : `${num} ${word}s`;
};

export const formatCourseName = (course: Course): string => {
  // Default mapping: use the enum key itself if not overridden
  const defaultLabels: Record<Course, string> = Object.values(Course).reduce(
    (acc, key) => {
      acc[key as Course] = key; // Default to the enum value itself
      return acc;
    },
    {} as Record<Course, string>,
  );

  return {
    ...defaultLabels,
    [Course.Kana]: 'Hiragana/Katakana',
    [Course.Kanji]: 'Kanji',
    [Course.Vocab]: 'Vocab',
  }[course];
};

export const getHasAudio = (course: Course, flipped: boolean, settings: UserSettings, material: FlashcardMaterial): boolean => {
  if (!material.audio_url) {
    return false;
  }
  if (course === Course.Kanji && !flipped) {
    return false;
  }
  if (course === Course.Kana && !flipped) {
    return false;
  }
  if (course === Course.Vocab && !flipped && settings.vocab_prompt === 'text') {
    return false;
  }
  return true;
};

export const getIsAudioAutoplay = (course: Course, flipped: boolean, settings: UserSettings): boolean => {
  if (course === Course.Kanji && !settings.audio_autoplay) {
    return false;
  }
  if (course === Course.Vocab && !settings.vocab_audio_autoplay) {
    return false;
  }
  if (course === Course.Vocab && flipped && (settings.vocab_prompt === 'audio' || settings.vocab_prompt === 'audio-text')) {
    // the audio plays once on the flashcard front, doesn't need to play again on the back
    return false;
  }
  return true;
};

export const copySentenceToClipboard = (sentence: string): void => {
  if (!sentence) {
    return;
  }

  void navigator.clipboard.writeText(sentence.replace(/\[[^\]]*\]/g, '').replace(/[\s]/g, ''));
};
