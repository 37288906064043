import React, { useEffect, useState } from 'react';
import { flushSync } from 'react-dom';
import axios from 'axios';
import type { AdminVocab, AdminVocabFilters } from '../../types';
import { getFilteredVocabs } from '../../admin';
import { formatMaterialSentence } from '../../common';
// import ReadingsRow from './ReadingsRow';
import { Link } from 'react-router-dom';

const VocabsIndex: React.FC = () => {
  /* Hooks */

  const [vocabs, setVocabs] = useState<AdminVocab[]>([]);
  const [filteredVocabs, setFilteredVocabs] = useState<AdminVocab[]>([]);
  const [filters, setFilters] = useState<AdminVocabFilters>({
    noUnderline: false,
    noImage: false,
    needsImageQA: false,
    noBreakdown: false,
  });
  const [level, setLevel] = useState<string>('1');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    void (async () => {
      flushSync(() => { setIsLoading(true) });
      const res = await axios.get(`/api/admin/vocabs?level=${level}`);
      setVocabs(res.data.vocabs as AdminVocab[]);
    })();
  }, [level]);

  useEffect(() => {
    setFilteredVocabs(getFilteredVocabs(vocabs, activeFilters()));
    setIsLoading(false);
  }, [JSON.stringify(vocabs), JSON.stringify(filters)]);

  /* Handlers */

  const handleLevelChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setLevel(event.target.value);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>, filterName: string): void => {
    setFilters((prev) => ({ ...prev, [filterName]: event.target.checked }));
  };

  const activeFilters = (): string[] => {
    return Object.keys(filters).filter((key) => !!filters[key as keyof AdminVocabFilters]);
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-2">
          <select className="form-select" value={level} onChange={handleLevelChange} disabled={isLoading}>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
          </select>
        </div>

        <div className="col-md-2">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="noSentenceOnly"
              checked={filters.noUnderline}
              onChange={(event) => { handleFilterChange(event, 'noUnderline') }}
              disabled={isLoading}
            />
            <label className="form-check-label" htmlFor="noSentenceOnly">
              no vocab underline
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="filterNoBreakdown"
              checked={filters.noBreakdown}
              onChange={(event) => { handleFilterChange(event, 'noBreakdown') }}
              disabled={isLoading}
            />
            <label className="form-check-label" htmlFor="filterNoBreakdown">
              no breakdown
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="filterNoImage"
              checked={filters.noImage}
              onChange={(event) => { handleFilterChange(event, 'noImage') }}
              disabled={isLoading}
            />
            <label className="form-check-label" htmlFor="filterNoImage">
              no image
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="filterNeedsImageQA"
              checked={filters.needsImageQA}
              onChange={(event) => { handleFilterChange(event, 'needsImageQA') }}
              disabled={isLoading}
            />
            <label className="form-check-label" htmlFor="filterNeedsImageQA">
            needs image QA
            </label>
          </div>
        </div>
      </div>

      <br/>
      <p>Showing {filteredVocabs.length} readings</p>

      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Level</th>
            <th>Vocab</th>
            <th>Reading</th>
            <th>Meaning</th>
            <th>Sentence</th>
            <th>Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && 'loading...'}
          {!isLoading && filteredVocabs.map((vocab: AdminVocab) =>
            <tr key={vocab.id}>
              <td>{vocab.id}</td>
              <td>{vocab.level}</td>
              <td style={{ whiteSpace: 'nowrap' }}>
                {vocab.kanji}
              </td>
              <td style={{ whiteSpace: 'nowrap' }}>
                {vocab.reading}
              </td>
              <td style={{ whiteSpace: 'nowrap' }}>
                {vocab.definition}
              </td>
              <td>
                <span dangerouslySetInnerHTML={{ __html: formatMaterialSentence(vocab.flashcard_material, vocab.level, true) }} />
                <br/>
                {vocab.flashcard_material.sentence_meaning}
              </td>
              <td>
                {!!vocab.flashcard_material.image_url && (
                  <img width="200px" src={vocab.flashcard_material.image_url} />
                )}
              </td>
              <td>
                <Link to={`/admin/vocabs/${vocab.id}?filters=${activeFilters().join('+')}`} className="btn btn-outline-secondary btn-sm" target="_blank">View</Link>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default VocabsIndex;
