import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import AdminLayout from './AdminLayout';
import ReadingsIndex from './ReadingsIndex';
import ReadingDetails from './ReadingDetails';
import KanjiIndex from './KanjiIndex';
import KanjiDetails from './KanjiDetails';
import UsersIndex from './UsersIndex';
import UserDetails from './UserDetails';
import VocabsIndex from './VocabsIndex';
import VocabDetails from './VocabDetails';
import KanaIndex from './KanaIndex';
import KanaDetails from './KanaDetails';

const queryClient = new QueryClient()

const AdminApp: React.FC = () => {
  const router = createBrowserRouter([
    {
      path: '/admin',
      element: <AdminLayout />,
      children: [
        { index: true, element: <ReadingsIndex /> },
        { path: '/admin/users', element: <UsersIndex /> },
        { path: '/admin/users/:id', element: <UserDetails /> },
        { path: '/admin/readings', element: <ReadingsIndex /> },
        { path: '/admin/readings/:id', element: <ReadingDetails /> },
        { path: '/admin/kanji', element: <KanjiIndex /> },
        { path: '/admin/kanji/:id', element: <KanjiDetails /> },
        { path: '/admin/vocabs', element: <VocabsIndex /> },
        { path: '/admin/vocabs/:id', element: <VocabDetails /> },
        { path: '/admin/kanas', element: <KanaIndex /> },
        { path: '/admin/kanas/:id', element: <KanaDetails /> },
      ],
    },
  ]);

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen />
    </QueryClientProvider>
  );
};

export default AdminApp;
