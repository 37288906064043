import React from 'react';
import type { UserSettings, Flashcard, SentenceBreakdownPart } from '../types';
import {
  formatMaterialSentence,
  validBreakdownPart,
  copySentenceToClipboard,
} from '../common';

interface Props {
  flashcard: Flashcard
  flipped: boolean
  settings: UserSettings
}

const VocabCardContent: React.FC<Props> = ({
  flashcard,
  flipped,
  settings,
}) => {
  const sentenceClasses = ['flashcard-sentence']
  sentenceClasses.push('flashcard-vocab');
  sentenceClasses.push(`flashcard-vocab-prompt-${settings.vocab_prompt}`);
  if (settings.vocab_prompt === 'audio' && settings.vocab_hide_text) {
    sentenceClasses.push('flashcard-vocab-hide-text');
  }

  if (!flashcard.material?.sentence || !flashcard.material?.sentence_meaning) {
    return 'Invalid card';
  }

  return (
    <>
      <h3 className="biz-udpmincho-regular">
        <span className={sentenceClasses.join(' ')}>
          <span dangerouslySetInnerHTML={{ __html: formatMaterialSentence(flashcard.material, flashcard.level, flipped) }} />
        </span>

        {flipped && (
        <><a style={{ fontSize: '18px' }} href="#" className="text-very-muted" onClick={() => { copySentenceToClipboard(flashcard.material?.sentence || '') }}><i className="bi bi-copy"></i></a>{' '}</>
        )}
      </h3>

      <p style={{ visibility: flipped ? 'visible' : 'hidden' }}>{flashcard.material.sentence_meaning}</p>

      {flashcard.material.sentence_breakdown && (
        <p className={`sentence-breakdown ${settings.vocab_prompt === 'audio' && settings.vocab_hide_text ? 'vocab-hide-text' : ''}`} style={{ visibility: flipped ? 'visible' : 'hidden' }}>
          <ul>
            {flashcard.material.sentence_breakdown.breakdown.filter((bp: SentenceBreakdownPart) => validBreakdownPart(bp)).map((bd: SentenceBreakdownPart, i: number) => (
              <li key={i}><span className="sentence-breakdown-word-pronunciation">{bd.word} ({bd.pronunciation})</span>: {bd.meaning}</li>
            ))}
          </ul>
        </p>
      )}
    </>
  );
};

export default VocabCardContent;
