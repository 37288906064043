import React, { useEffect } from 'react'
import type { Stats, StatsLevel, Course } from '../types';
import { colorClassFromLevel, isLevelUntrained, loadTooltips } from '../common';

interface Props {
  course: Course
  stats?: Stats
  userLevel?: number
  userSubscribed: boolean
}

const StatsPane: React.FC<Props> = ({ course, stats, userLevel, userSubscribed }) => {
  useEffect(loadTooltips, []);

  if (!stats?.levels.length) {
    return <></>;
  }

  return (
    <>
      <h4>Stats</h4>
      <table className="table table-sm">
        <thead>
          <tr>
            <th colSpan={2}>Level</th>
            <th className="text-untrained">Untrained</th>
            <th className="text-learning">Learning</th>
            <th className="text-intermediate">Interm. <i className="bi bi-question-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Intermediate: Cards that are still easy after a space of 6+ days"></i></th>
            <th className="text-mastered">Mastered <i className="bi bi-question-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Mastered: Cards that are still easy after a space of 30+ days"></i></th>
          </tr>
        </thead>
        <tbody>
          {stats.levels.map((statsLevel: StatsLevel) => {
            const levelLocked = statsLevel.level !== 1 && !userSubscribed;
            return <>
              <tr className={`${isLevelUntrained(statsLevel.secondary ?? statsLevel.primary) || levelLocked ? 'text-very-muted' : ''}`}>
                <td rowSpan={statsLevel.secondary ? 2 : 1}>
                  <span className={`badge ${colorClassFromLevel(statsLevel.level)}`}>{statsLevel.level}</span>
                  {statsLevel.level === userLevel && (
                    <>
                      <br/>
                      {'⛷️'}
                    </>
                  )}
                </td>
                <td className="fw-bold">{statsLevel.primary.name}</td>
                <td className="fw-bold">{levelLocked ? <i className="bi bi-lock"></i> : statsLevel.primary.untrained}</td>
                <td className="fw-bold">{levelLocked ? <i className="bi bi-lock"></i> : statsLevel.primary.learning}</td>
                <td className="fw-bold">{levelLocked ? <i className="bi bi-lock"></i> : statsLevel.primary.intermediate}</td>
                <td className="fw-bold">{levelLocked ? <i className="bi bi-lock"></i> : statsLevel.primary.mastered}</td>
              </tr>
              {statsLevel.secondary && (
                <tr className={`table-light ${isLevelUntrained(statsLevel.secondary) || levelLocked ? 'text-very-muted' : ''}`}>
                  <td>{statsLevel.secondary.name}</td>
                  <td>{levelLocked ? <i className="bi bi-lock"></i> : statsLevel.secondary.untrained}</td>
                  <td>{levelLocked ? <i className="bi bi-lock"></i> : statsLevel.secondary.learning}</td>
                  <td>{levelLocked ? <i className="bi bi-lock"></i> : statsLevel.secondary.intermediate}</td>
                  <td>{levelLocked ? <i className="bi bi-lock"></i> : statsLevel.secondary.mastered}</td>
                </tr>
              )}
            </>;
          })}

          <tr>
            <td rowSpan={stats.levels[0].secondary ? 2 : 1}>All</td>
            <td className="fw-bold">{stats.levels[0].secondary ? stats.levels[0].primary.name : ''}</td>
            <td className="fw-bold">{stats.levels.reduce((acc: number, statsLevel: StatsLevel) => (acc + statsLevel.primary.untrained), 0)}</td>
            <td className="fw-bold">{stats.levels.reduce((acc: number, statsLevel: StatsLevel) => (acc + statsLevel.primary.learning), 0)}</td>
            <td className="fw-bold">{stats.levels.reduce((acc: number, statsLevel: StatsLevel) => (acc + statsLevel.primary.intermediate), 0)}</td>
            <td className="fw-bold">{stats.levels.reduce((acc: number, statsLevel: StatsLevel) => (acc + statsLevel.primary.mastered), 0)}</td>
          </tr>
          {stats.levels[0].secondary && (
            <tr className={'table-light'}>
              <td>{stats.levels[0].secondary.name}</td>
              <td>{stats.levels.reduce((acc: number, statsLevel: StatsLevel) => (acc + (statsLevel.secondary?.untrained ?? 0)), 0)}</td>
              <td>{stats.levels.reduce((acc: number, statsLevel: StatsLevel) => (acc + (statsLevel.secondary?.learning ?? 0)), 0)}</td>
              <td>{stats.levels.reduce((acc: number, statsLevel: StatsLevel) => (acc + (statsLevel.secondary?.intermediate ?? 0)), 0)}</td>
              <td>{stats.levels.reduce((acc: number, statsLevel: StatsLevel) => (acc + (statsLevel.secondary?.mastered ?? 0)), 0)}</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default StatsPane;
