import React, { useEffect, useState, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useParams, Link, useNavigate, useSearchParams } from 'react-router-dom';
import type { Course, AdminMaterial, Flashcard, MaterialType } from '../../types';
import { getFlashcard } from '../../actions';
import { getFilteredMaterials } from '../../admin';
import FlashcardCard from '../FlashcardCard';
import type { MouseEvent, ReactNode } from 'react';

interface Props {
  course: Course
  materialType: MaterialType
  urlPath: string
  children: ReactNode
  setMaterialForParent: (material: AdminMaterial) => void
  setMaterialValues: (material: AdminMaterial) => void
  materialSaveData: any
  isUpdating: boolean
  setIsUpdating: (value: boolean) => void
}

const MaterialDetails: React.FC<Props> = ({
  course,
  materialType,
  urlPath,
  children,
  setMaterialForParent,
  setMaterialValues,
  materialSaveData,
  isUpdating,
  setIsUpdating,
}) => {
  /* Hooks */

  const [material, setMaterial] = useState<AdminMaterial | undefined>(undefined);
  const [flashcard, setFlashcard] = useState<Flashcard | undefined>(undefined);
  const [prevMaterial, setPrevMaterial] = useState<AdminMaterial | undefined>(undefined);
  const [nextMaterial, setNextMaterial] = useState<AdminMaterial | undefined>(undefined);
  const [materialIndex, setMaterialIndex] = useState<number | undefined>(undefined);
  const [materialCount, setMaterialCount] = useState<number | undefined>(undefined);
  const [activeTab, setActiveTab] = useState<string>(localStorage.getItem('adminMaterialTabDefault') ?? 'Flashcard');

  const { id: materialId } = useParams();

  const navigate = useNavigate();

  const audioRef = useRef<HTMLAudioElement | null>(null);

  const materialQuery = useQuery({
    queryKey: ['admin', 'material', materialType, materialId],
    queryFn: async () =>
      await axios
        .get(`/api/admin/materials/${materialId}?type=${materialType}`)
        .then((res) => res.data),
  });

  const materialsQuery = useQuery({
    queryKey: ['admin', 'materials', materialType],
    queryFn: async () =>
      await axios
        .get(`/api/admin/materials?type=${materialType}&level=${material?.level}`)
        .then((res) => res.data),
    enabled: !!material
  });

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (material?.id && materialsQuery?.data?.materials) {
      const materials = materialsQuery.data.materials as AdminMaterial[];
      let filteredMaterials = materials;
      const filtersString = searchParams.get('filters');
      if (filtersString) {
        filteredMaterials = getFilteredMaterials(materialType, materials, filtersString.split('+'));
      }
      const index = filteredMaterials.findIndex((_material: AdminMaterial) => _material.id === material.id);
      setMaterialIndex(index as number | undefined);
      setMaterialCount(filteredMaterials.length);
      setPrevMaterial(index > 0 ? filteredMaterials[index - 1] : undefined);
      setNextMaterial(index < filteredMaterials.length - 1 ? filteredMaterials[index + 1] : undefined);
    }
  }, [material?.id, !!materialsQuery?.data?.materials, searchParams.get('filters')]);

  useEffect(() => {
    if (materialId) {
      void materialQuery.refetch();
    }
  }, [materialId]);

  useEffect(() => {
    playAudio();
  }, [materialId, flashcard?.material.audio_url, audioRef.current?.src]);

  useEffect(() => {
    const _material: AdminMaterial = materialQuery?.data?.material;
    if (_material) {
      setMaterial(_material);
      setMaterialForParent(_material);
      setMaterialValues(_material);
      void (async () => {
        const { flashcard: _flashcard } = await getFlashcard(course, materialType, Number(materialId));
        setFlashcard(_flashcard);
      })()
    }
  }, [JSON.stringify(materialQuery?.data)]);

  // Use useEffect to add the event listener
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    JSON.stringify(material),
    prevMaterial?.id,
    nextMaterial?.id,
  ]);

  /* Handlers */

  // Function to handle the key press event
  const handleKeyDown = (event: KeyboardEvent): void => {
    const activeElement = document.activeElement;
    if (
      activeElement instanceof HTMLInputElement ||
      activeElement instanceof HTMLTextAreaElement ||
      (activeElement && activeElement.getAttribute('contenteditable') === 'true')
    ) {
      // If the user is typing in an input or textarea, do nothing
      return;
    }

    if (event.key === 'ArrowRight' && nextMaterial?.id) {
      void (async () => {
        navigate(`/admin/${urlPath}/${nextMaterial.id}?filters=${searchParams.get('filters')}`);
      })();
    } else if (event.key === 'ArrowLeft' && prevMaterial?.id) {
      navigate(`/admin/${urlPath}/${prevMaterial.id}?filters=${searchParams.get('filters')}`);
    }
  };

  const handleSave = async (): Promise<void> => {
    setIsUpdating(true);
    await axios.patch(
      `/api/admin/materials/${material?.id}`,
      { type: materialType, ...materialSaveData },
      {
        headers: {
          'Content-Type': 'application/json', // Tells Rails it's a JSON request
          Accept: 'application/json', // Ensures Rails responds with JSON
        }
      }
    );
    await materialQuery.refetch();
    setIsUpdating(false);
  };

  const handleFetchAudioClick = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();
    void handleAction('fetch_audio');
  };

  const handleFetchImageClick = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();
    void handleAction('fetch_image');
  };

  const handleFetchImageSituationClick = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();
    void handleAction('fetch_image_situation');
  };

  const handleFetchImageDescriptionClick = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();
    void handleAction('fetch_image_description');
  };

  const handleUploadImageClick = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();
    void handleAction('upload_image');
  };

  const handleApproveImageClick = (event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    void handleAction('approve_image');
  };

  const handleUnapproveImageClick = (event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    void handleAction('unapprove_image');
  };

  const handleApproveAudioClick = (event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    void handleAction('approve_audio');
  };

  const handleUnapproveAudioClick = (event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    void handleAction('unapprove_audio');
  };

  const handleAction = async (action: string): Promise<void> => {
    setIsUpdating(true);
    await axios.post(
      `/api/admin/materials/${material?.id}/actions`,
      { type: materialType, actions: { [action]: true } },
      {
        headers: {
          'Content-Type': 'application/json', // Tells Rails it's a JSON request
          Accept: 'application/json', // Ensures Rails responds with JSON
        }
      }
    );
    await materialQuery.refetch();
    setIsUpdating(false);
  };

  const handleTabClick = (event: MouseEvent<HTMLAnchorElement>, tabName: string): void => {
    event.preventDefault();
    localStorage.setItem('adminMaterialTabDefault', tabName);
    setActiveTab(tabName);
  };

  const playAudio = (): void => {
    if (audioRef.current) {
      void audioRef.current.play();
    }
  };

  if (materialQuery.isLoading || materialsQuery.isLoading || !material) return 'Loading...';

  const audioUrl = flashcard?.material.audio_url;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4">
          <p>
            <Link to={`/admin/${urlPath}/${prevMaterial?.id}?filters=${searchParams.get('filters')}`} className={`btn btn-outline-secondary btn-sm ${isUpdating || !prevMaterial?.id ? 'disabled' : ''}`}>Previous</Link>{' '}
            <Link to={`/admin/${urlPath}/${nextMaterial?.id}?filters=${searchParams.get('filters')}`} className={`btn btn-outline-secondary btn-sm ${isUpdating || !nextMaterial?.id ? 'disabled' : ''}`}>Next</Link>{' '}
            {materialIndex === undefined ? '' : materialIndex + 1} / {materialCount}
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          {children}
        </div>

        <div className="col-md-4">
          <button type="button" className={`btn btn-primary ${isUpdating ? 'disabled' : ''}`} onClick={() => { void handleSave() }}>Save</button>
          <button type="button" className={`ms-2 btn btn-outline-secondary ${isUpdating ? 'disabled' : ''}`} onClick={(event) => { void handleFetchAudioClick(event) }}>Fetch audio</button>
          <button type="button" className={`ms-2 btn btn-outline-secondary ${isUpdating ? 'disabled' : ''}`} onClick={(event) => { void handleFetchImageSituationClick(event) }}>Fetch image situation</button>
          <button type="button" className={`ms-2 btn btn-outline-secondary ${isUpdating ? 'disabled' : ''}`} onClick={(event) => { void handleFetchImageDescriptionClick(event) }}>Fetch image description</button>
          <button type="button" className={`ms-2 btn btn-outline-secondary ${isUpdating ? 'disabled' : ''}`} onClick={(event) => { void handleFetchImageClick(event) }}>Fetch image</button>

          <hr />

          <h3>DALLE raw image</h3>
          {!!material.image_tmp && (
            <>
              <p><button type="button" className="btn btn-outline-secondary btn-sm" onClick={(event) => { void handleUploadImageClick(event) }}>Upload</button></p>
              <div className="mt-4 card">
                <div className="card-body">
                  <img width="100%" src={material.image_tmp} />
                </div>
              </div>
            </>
          )}

          <h3>Audio</h3>
          {!!audioUrl && (
            <div>
              <audio ref={audioRef} src={audioUrl} />
              <button className="btn btn-outline-secondary btn-sm me-2" onClick={playAudio}>Play</button>
              {material.is_audio_approved ? <button className="btn btn-danger btn-sm" type="button" onClick={handleUnapproveAudioClick}>Unapprove</button> : <button className="btn btn-success btn-sm" type="button" onClick={handleApproveAudioClick}>Approve</button>}
            </div>
          )}

          <h3 className="mt-4">Image</h3>
          <form
            action={`/api/admin/materials/${material?.id}`}
            method="POST"
            encType="multipart/form-data"
          >
            <input type="hidden" name="_method" value="patch" />
            <input type="file" id="material_image" name="material[image_file]" accept="image/*" />
            <button className="btn btn-outline-secondary btn-sm" type="submit">Upload</button>
          </form>
        </div>

        <div className="col-md-4">
          <ul className="nav nav-tabs">
            {['Flashcard', 'Flashcard (back)', 'Image'].map((tabName: string) => (
              <li key={tabName} className="nav-item">
                <a className={`nav-link ${activeTab === tabName ? 'active' : ''}`} href="#" onClick={(event) => { handleTabClick(event, tabName) }}>{tabName}</a>
              </li>
            ))}
          </ul>

          {activeTab === 'Flashcard' && (
            <div className="mt-4 card">
              <div className="card-body">
                {!!flashcard && <FlashcardCard flashcard={flashcard} userSubscribed={false} flipped={false} loading={false} onFlip={() => {}} onNext={() => {}} settings={{}} />}
              </div>
            </div>
          )}

          {activeTab === 'Flashcard (back)' && (
            <div className="mt-4 card">
              <div className="card-body">
                {!!flashcard && <FlashcardCard flashcard={flashcard} userSubscribed={false} flipped={true} loading={false} onFlip={() => {}} onNext={() => {}} settings={{}} />}
              </div>
            </div>
          )}

          {activeTab === 'Image' && flashcard?.material?.image_url && (
            <div className="mt-4 card">
              <div className="card-body">
                {flashcard.material.is_image_approved ? <button className="btn btn-danger btn-sm" type="button" onClick={handleUnapproveImageClick}>Unapprove</button> : <button className="btn btn-success btn-sm" type="button" onClick={handleApproveImageClick}>Approve</button>}
                <img width="100%" src={flashcard.material.image_url} />
                <p>
                  {flashcard.material.sentence}<br/>
                  {flashcard.material.sentence_meaning}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MaterialDetails;
