import React from 'react';
import type { Kanji, KanjiVocab, KanjiReading } from '../types';
import { colorClassFromLevel } from '../common';

interface Props {
  kanji?: Kanji
  flipped: boolean
  currentReading?: string
}

interface KanjiReadingItemProps {
  reading: KanjiReading
  currentReading?: string
}

const KanjiReadingItem: React.FC<KanjiReadingItemProps> = ({ reading, currentReading }) => {
  return (
    <span key={reading.reading} className={reading.reading === currentReading ? 'word-highlight' : ''}>
      {reading.reading}
      {reading.grade === 'middle' && <>{' '}<span className="badge bg-brown">7</span></>}
      {reading.grade === 'high' && <>{' '}<span className="badge bg-black">9</span></>}
    </span>
  );
};

const KanjiDefinition: React.FC<Props> = ({ kanji, flipped, currentReading }) => {
  if (!kanji) {
    return <></>;
  }

  return (
    <>
      <h4 className="mt-4">Kanji Definition</h4>
      <table className="kanji-def">
        <tr>
          <td className="hina-mincho-regular kanji-ji" style={{ fontSize: '80px', verticalAlign: 'top' }}>{kanji.character}</td>
          <td>
            <br/>
            <span className={`badge ${colorClassFromLevel(kanji.level)}`}>{kanji.level}</span>
            <br/>
            <span className="badge bg-secondary">音</span>{' '}
              {flipped && kanji.on_readings.map((ony: KanjiReading, index: number) => <><KanjiReadingItem reading={ony} currentReading={currentReading} />{index < kanji.on_readings.length - 1 && '、'}</>)}
            <br/>
            <span className="badge bg-secondary">訓</span>{' '}
              {flipped && kanji.kun_readings.map((kuny: KanjiReading, index: number) => <><KanjiReadingItem reading={kuny} currentReading={currentReading} />{index < kanji.kun_readings.length - 1 && '、'}</>)}
            <br/>
            <span className="mt-1 badge bg-secondary">語句</span>{' '}
            {flipped && kanji.vocabs.map((kv: KanjiVocab) => (
              <>
                <br/>
                {kv.vocab} ({kv.vocab_hiragana}): {kv.vocab_meaning}{' '}
                {kv.level !== kanji.level && <span className={`badge ${colorClassFromLevel(kv.level)}`}>{kv.level}</span>}
              </>
            ))}
          </td>
        </tr>
      </table>
    </>
  );
};

export default KanjiDefinition;
