import React, { useEffect, useState } from 'react';
import axios from 'axios';
import type { User, Stats, UserProfile } from '../../types';
import { Course } from '../../types';
import { useParams } from 'react-router-dom';
import StatsPane from '../StatsPane';

const UserDetails: React.FC = () => {
  /* Hooks */

  const { id: userId } = useParams();
  const [user, setUser] = useState<User | undefined>(undefined);
  const [stats, setStats] = useState<Stats | undefined>(undefined);
  const [profile, setProfile] = useState<UserProfile | undefined>(undefined);

  useEffect(() => {
    void (async () => {
      const res = await axios.get(`/api/admin/users/${userId}`);
      setUser(res.data.user as User);
      setStats(res.data.stats as Stats);
      setProfile(res.data.profile as UserProfile);
    })();
  }, []);

  /* end hooks */

  if (!user || !stats || !profile) {
    return 'Loading...';
  }

  const course = Course.Kanji;

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h3>{user.name}</h3>
          <p>{user.email}</p>
        </div>

        <div className="col-md-6">
          <StatsPane course={course} stats={stats} userLevel={profile.level[Course.Kanji]} userSubscribed={profile.subscription_status === 'active'} />
        </div>

        <div className="col-md-6">
          <table className="table">
            <thead>
              <tr>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>ID</th>
                <td>{user.id}</td>
              </tr>
              <tr>
                <th>Created Date</th>
                <td>{user.created_at.toString()}</td>
              </tr>
              <tr>
                <th>Provider</th>
                <td>{user.provider}</td>
              </tr>
              <tr>
                <th>Timezone</th>
                <td>{user.timezone}</td>
              </tr>
              <tr>
                <th>Level</th>
                <td>{user.level.kanji ?? 1}</td>
              </tr>
              <tr>
                <th>Flashcard Count</th>
                <td>{user.flashcard_count}</td>
              </tr>
              <tr>
                <th>Subscription Status</th>
                <td>{user.subscription_status}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
