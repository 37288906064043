import React from 'react';
import type { ReactNode } from 'react';
import type { AdminReading, AdminMaterial } from '../../types';
import MaterialIndex from './MaterialIndex';
import { Link } from 'react-router-dom';

const ReadingsIndex: React.FC = () => {
  const getReadingRow = (material: AdminMaterial, filterString: string): ReactNode => {
    const reading = material as AdminReading;
    return (
      <>
        <td>{reading.level}</td>
        <td>{reading.grade}</td>
        <td>
          <Link to={`/admin/kanji/${reading.kanji_id}`} target="_blank" className="link-unstyled">{reading.kanji}</Link>
        </td>
        <td style={{ whiteSpace: 'nowrap' }}>
          {reading.reading}
        </td>
        <td style={{ whiteSpace: 'nowrap' }}>
          <Link to={`/admin/vocabs/${reading.vocab_id}`}>{reading.vocab_id}</Link>
        </td>
        <td>
          {reading.flashcard_material.vocab} ({reading.flashcard_material.vocab_hiragana}): {reading.flashcard_material.vocab_meaning}
        </td>
        <td width="20%">
          {reading.flashcard_material.sentence}<br/>
          {reading.flashcard_material.sentence_meaning}
        </td>
        <td>
          {!!reading.flashcard_material.image_url && (
            <img width="200px" src={reading.flashcard_material.image_url} />
          )}
        </td>
        <td>
          <Link to={`/admin/readings/${reading.id}?filters=${filterString}`} className="btn btn-outline-secondary btn-sm" target="_blank">View</Link>
        </td>
      </>
    );
  };

  return (
    <div className="container">
      <MaterialIndex materialType={'KanjiReading'} getMaterialRow={getReadingRow} />
    </div>
  );
};

export default ReadingsIndex;
