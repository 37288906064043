import axios from 'axios';
import type { Flashcard, Stats, KanjiMastery, Course } from './types';

export const getFlashcard = async (course: Course, materialType?: string, materialId?: number): Promise<{ flashcard: Flashcard, user_next_review_in_minutes: number, review_count: number }> => {
  return await axios.get(`/api/flashcard?course=${course}&material_type=${materialType ?? ''}&material_id=${materialId ?? ''}`).then((res) => res.data);
};

export const postFlashcard = async (id: number, grade: number, fetchNew: boolean = false): Promise<{ flashcard: Flashcard, new_flashcard: Flashcard, new_level?: number, user_next_review_in_minutes: number, review_count: number }> => {
  return await axios.post('/api/flashcard', { id, grade, fetch_new: fetchNew }).then((res) => res.data);
};

export const getStats = async (course: Course): Promise<Stats> => {
  return await axios.get(`/api/users/stats?course=${course}`).then((res) => res.data);
};

export const getAllKanjiMastery = async (): Promise<KanjiMastery> => {
  return await axios.get('/api/users/kanji_mastery').then((res) => res.data);
};

export const postUserTimezone = async (timezone: string): Promise<void> => {
  await axios.post('/api/users/timezone', { timezone });
};
