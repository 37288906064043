import React, { useEffect, useState } from 'react';
import type { ReactNode, MouseEvent } from 'react';
import { flushSync } from 'react-dom';
import axios from 'axios';
import type { AdminReadingFilters, AdminReadingActions, AdminMaterial, MaterialType } from '../../types';
import { getFilteredMaterials } from '../../admin';
import { useSearchParams } from 'react-router-dom';

type AdminMaterialFilters = AdminReadingFilters;
type AdminMaterialActions = AdminReadingActions;

interface Props {
  materialType: MaterialType
  getMaterialRow: (material: AdminMaterial, filterString: string) => ReactNode
}

const MaterialIndex: React.FC<Props> = ({ materialType, getMaterialRow }) => {
  /* Hooks */

  const [materials, setMaterials] = useState<AdminMaterial[]>([]);
  const [filteredMaterials, setFilteredMaterials] = useState<AdminMaterial[]>([]);
  const [level, setLevel] = useState<string | undefined>(undefined);
  const [filters, setFilters] = useState<AdminMaterialFilters>({
    noImage: false,
    noSentence: false,
    noUnderline: false,
    hasVocabId: false,
    noVocabId: false,
    noAudio: false,
    needsImageQA: false,
    needsAudioQA: false,
    noBreakdown: false,
    noImageSituation: false,
    noImageDescription: false,
  });
  const [activeFetchActions, setActiveFetchActions] = useState<AdminMaterialActions>({});
  const [bulkFetchNum, setBulkFetchNum] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    void (async () => {
      if (!level) {
        return;
      }

      flushSync(() => { setIsLoading(true) });
      const res = await axios.get(`/api/admin/materials?type=${materialType}&level=${level}`);
      setMaterials(res.data.materials as AdminMaterial[]);
    })();
  }, [level]);

  useEffect(() => {
    if (searchParams.get('level')) {
      setLevel(searchParams.get('level') as string);
    } else {
      setLevel('1');
    }
  }, [searchParams.get('level')]);

  useEffect(() => {
    if (level) {
      setFilteredMaterials(getFilteredMaterials(materialType, materials, activeFilters()));
      setIsLoading(false);
    }
  }, [JSON.stringify(materials), JSON.stringify(filters)]);

  /* Handlers */

  const handleLevelChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const newLevel = event.target.value;
    setLevel(newLevel);
    const url = new URL(window.location.href);
    url.searchParams.set('level', newLevel); // Add or update the 'url' query parameter
    window.history.pushState({}, '', url); // Update the browser's URL without reloading
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>, filterName: string): void => {
    setFilters((prev) => ({ ...prev, [filterName]: event.target.checked }));
  };

  const handleFetchButtonClick = async (event: MouseEvent<HTMLButtonElement>, key: keyof AdminMaterialActions): Promise<void> => {
    event.preventDefault();
    await fetchBulk({ [key]: true });
  };

  const fetchBulk = async (actions: AdminMaterialActions): Promise<void> => {
    setActiveFetchActions(actions);
    try {
      const _filteredMaterials = [...filteredMaterials];
      let index = 0;
      for (const material of _filteredMaterials) {
        setBulkFetchNum(index + 1);
        const updatedMaterial = await fetchActionsWithRetries(material.id, actions);
        if (updatedMaterial) {
          setFilteredMaterials((prevItems) => {
            return prevItems.map((item) => (item.id === material.id ? updatedMaterial : item));
          });
        }
        index += 1;
      }
    } catch (e) {
      console.error(e);
    } finally {
      setBulkFetchNum(0);
      setActiveFetchActions({});
    }
  };

  const fetchActionsWithRetries = async (materialId: number, actions: AdminMaterialActions): Promise<AdminMaterial | undefined> => {
    let attempts = 0;
    const maxRetries = 3;
    while (attempts < maxRetries) {
      try {
        const result = await axios.post(
          `/api/admin/materials/${materialId}/actions`,
          { type: materialType, actions },
          {
            headers: {
              'Content-Type': 'application/json', // Tells Rails it's a JSON request
              Accept: 'application/json', // Ensures Rails responds with JSON
            }
          }
        );
        return result.data;
      } catch (error) {
        attempts++;
        if (attempts >= maxRetries) {
          throw error; // Max retries reached, rethrow the error
        }
        console.log(`Retrying... (${attempts}/${maxRetries})`);
        await new Promise((resolve) => setTimeout(resolve, 5000)); // Wait before retrying
      }
    }
  };

  const activeFilters = (): string[] => {
    return Object.keys(filters).filter((key) => !!filters[key as keyof AdminMaterialFilters]);
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-2">
          <select className="form-select" disabled={isLoading} value={level} onChange={handleLevelChange}>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
          </select>
        </div>

        <div className="col-md-2">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="filterNoSentence"
              checked={filters.noSentence}
              onChange={(event) => { handleFilterChange(event, 'noSentence') }}
              disabled={isLoading}
            />
            <label className="form-check-label" htmlFor="filterNoSentence">
              no sentence
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="filterNoBreakdown"
              checked={filters.noBreakdown}
              onChange={(event) => { handleFilterChange(event, 'noBreakdown') }}
              disabled={isLoading}
            />
            <label className="form-check-label" htmlFor="filterNoBreakdown">
              no breakdown
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="filterNoImage"
              checked={filters.noImage}
              onChange={(event) => { handleFilterChange(event, 'noImage') }}
              disabled={isLoading}
            />
            <label className="form-check-label" htmlFor="filterNoImage">
              no image
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="filterNoUnderline"
              checked={filters.noUnderline}
              onChange={(event) => { handleFilterChange(event, 'noUnderline') }}
              disabled={isLoading}
            />
            <label className="form-check-label" htmlFor="filterNoUnderline">
              no vocab underline
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="filterHasVocabId"
              checked={filters.hasVocabId}
              onChange={(event) => { handleFilterChange(event, 'hasVocabId') }}
              disabled={isLoading}
            />
            <label className="form-check-label" htmlFor="filterHasVocabId">
              has vocab id
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="filterHasNoVocabId"
              checked={filters.noVocabId}
              onChange={(event) => { handleFilterChange(event, 'noVocabId') }}
              disabled={isLoading}
            />
            <label className="form-check-label" htmlFor="filterHasNoVocabId">
              has no vocab id
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="filterNoAudio"
              checked={filters.noAudio}
              onChange={(event) => { handleFilterChange(event, 'noAudio') }}
              disabled={isLoading}
            />
            <label className="form-check-label" htmlFor="filterNoAudio">
              no audio
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="filterNeedsImageQA"
              checked={filters.needsImageQA}
              onChange={(event) => { handleFilterChange(event, 'needsImageQA') }}
              disabled={isLoading}
            />
            <label className="form-check-label" htmlFor="filterNeedsImageQA">
            needs image QA
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="filterNeedsAudioQA"
              checked={filters.needsAudioQA}
              onChange={(event) => { handleFilterChange(event, 'needsAudioQA') }}
              disabled={isLoading}
            />
            <label className="form-check-label" htmlFor="filterNeedsAudioQA">
            needs audio QA
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="filterNoImageSituation"
              checked={filters.noImageSituation}
              onChange={(event) => { handleFilterChange(event, 'noImageSituation') }}
              disabled={isLoading}
            />
            <label className="form-check-label" htmlFor="filterNoImageSituation">
            no image situation
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="filterNoImageDescription"
              checked={filters.noImageDescription}
              onChange={(event) => { handleFilterChange(event, 'noImageDescription') }}
              disabled={isLoading}
            />
            <label className="form-check-label" htmlFor="filterNoImageDescription">
            no image description
            </label>
          </div>
        </div>

        {/*
        <div className="col-md-3">
          <input type="text" className="form-control" id="searchString" value={filterSearchString} onChange={handleSearchStringChange} placeholder="search" />
        </div>
        */}

        <div className="col-md-3">
          {[
            ['fetch_audio', 'Fetch audio'],
            ['fetch_and_upload_image', 'Fetch images'],
            ['fetch_breakdown', 'Fetch breakdowns'],
            ['fetch_image_situation', 'Fetch image situations'],
            ['fetch_image_description', 'Fetch image descriptions'],
          ].map(([key, label]) => (
            <div key={key}>
              <button type="button" className={`ms-2 btn btn-outline-secondary ${isLoading || Object.keys(activeFetchActions).length ? 'disabled' : ''}`} onClick={(event) => { void handleFetchButtonClick(event, key as keyof AdminMaterialActions) }}>{label}</button>
              {activeFetchActions[key as keyof AdminMaterialActions] && <>{' '} Fetching {bulkFetchNum}/{filteredMaterials.length}</>}
            </div>
          ))}
        </div>
      </div>

      <br/>
      <p>Showing {filteredMaterials.length} materials</p>

      <table className="table">
        <tbody>
          {isLoading && 'loading...'}
          {!isLoading && filteredMaterials.map((material: AdminMaterial, index: number) =>
            <tr key={material.id} className={bulkFetchNum && (bulkFetchNum - 1) === index ? 'table-warning' : ''}>
              {getMaterialRow(material, activeFilters().join('+'))}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default MaterialIndex;
