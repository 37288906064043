import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import type { AdminReading, AdminMaterial } from '../../types';
import MaterialDetails from './MaterialDetails';
import { Course } from '../../types';

const ReadingDetails: React.FC = () => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isVocabAlreadyUsed, setIsVocabAlreadyUsed] = useState<boolean>(false);
  const [reading, setReading] = useState<AdminReading>();
  const [readingValues, setReadingValues] = useState<Record<string, string>>({});

  useEffect(() => {
    const sameVocabMaterialIds = reading?.same_vocab_reading_ids;
    if (sameVocabMaterialIds?.length && reading?.id) {
      const isVocabAlreadyUsedOnAnotherKanjiMaterial = !sameVocabMaterialIds.includes(reading.id) || sameVocabMaterialIds.length > 1;
      setIsVocabAlreadyUsed(isVocabAlreadyUsedOnAnotherKanjiMaterial);
    } else {
      setIsVocabAlreadyUsed(false);
    }
  }, [(reading?.same_vocab_reading_ids ?? []).join(','), reading?.id]);

  const setReadingValuesFromData = (material: AdminReading): void => {
    setReadingValues({
      grade: material.grade || '',
      vocabId: (material.vocab_id ?? '') as string,
      sentenceImageSituation: material.image_situation || '',
      sentenceImageDescription: material.image_description || '',
    });
  };

  const saveData = {
    material: {
      grade: readingValues.grade,
      vocab_id: Number(readingValues.vocabId) || null,
    },
    sentence: {
      image_situation: readingValues.sentenceImageSituation || null,
      image_description: readingValues.sentenceImageDescription || null,
    }
  };

  /* Handlers */
  const handleGradeChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setReadingValues((prev) => ({ ...prev, grade: event.target.value }));
  };

  const handleVocabIdChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setReadingValues((prev) => ({ ...prev, vocabId: event.target.value }));
  };

  const handleSentenceImageSituationChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setReadingValues((prev) => ({ ...prev, sentenceImageSituation: event.target.value }));
  };

  const handleSentenceImageDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setReadingValues((prev) => ({ ...prev, sentenceImageDescription: event.target.value }));
  };

  return (
    <MaterialDetails
      course={Course.Kanji}
      materialType={'KanjiReading'}
      urlPath={'readings'}
      setMaterialForParent={setReading as (material: AdminMaterial) => void}
      setMaterialValues={setReadingValuesFromData as (material: AdminMaterial) => void}
      materialSaveData={saveData}
      isUpdating={isUpdating}
      setIsUpdating={setIsUpdating}
    >
      {!!reading && (
        <>
          <h2>{reading.kanji} [{reading.reading}]</h2>
          <table className="table table-borderless">
            <tbody>
              <tr>
                <th>ID</th>
                <td>{reading.id}</td>
              </tr>
              <tr>
                <th>Level</th>
                <td>{reading.level}</td>
              </tr>
              <tr>
                <th>Grade</th>
                <td>
                  <select className="form-select" value={readingValues.grade} disabled={isUpdating} onChange={handleGradeChange}>
                    <option value="elementary">Elementary</option>
                    <option value="middle">Middle</option>
                    <option value="high">High</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>Vocab ID</th>
                <td>
                  <select className={`form-select ${readingValues.vocabId && isVocabAlreadyUsed ? 'is-invalid' : ''}`} value={readingValues.vocabId} disabled={isUpdating || !reading.vocab_id_options?.length} onChange={handleVocabIdChange}>
                    <option value={''}></option>
                    {reading.vocab_id_options?.map((idText: any) => (
                      <option key={idText[0]} value={idText[0]}>{idText[1]}</option>
                    ))}
                  </select>
                  {readingValues.vocabId && <Link to={`/admin/vocabs/${readingValues.vocabId}`}>{readingValues.vocabId}</Link>}
                  {readingValues.vocabId && isVocabAlreadyUsed &&
                    <div id="validationReadingVocabIdFeedback" className="invalid-feedback">
                      This vocab is already in use.{' '}
                      {reading.same_vocab_reading_ids?.map((_id: number) => (
                        <>
                          <Link key={_id} to={`/admin/readings/${_id}`}>{_id}</Link>{' '}
                        </>
                      ))}
                    </div>
                  }
                </td>
              </tr>

              <tr>
                <th>Image Situation</th>
                <td>
                  <textarea
                    id="readingImageSituation"
                    className="form-control"
                    disabled={isUpdating || !readingValues.vocabId}
                    value={readingValues.sentenceImageSituation}
                    onChange={handleSentenceImageSituationChange}
                    rows={3}
                  />
                </td>
              </tr>
              <tr>
                <th>Image Description</th>
                <td>
                  <textarea
                    id="readingImageDescription"
                    className="form-control"
                    disabled={isUpdating || !readingValues.vocabId}
                    value={readingValues.sentenceImageDescription}
                    onChange={handleSentenceImageDescriptionChange}
                    rows={5}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </MaterialDetails>
  );
};

export default ReadingDetails;
