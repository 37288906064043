import React from 'react';
import type { ReactNode } from 'react';
import type { AdminKana, AdminMaterial } from '../../types';
import MaterialIndex from './MaterialIndex';
import { Link } from 'react-router-dom';

const KanaIndex: React.FC = () => {
  const getKanaRow = (material: AdminMaterial, filterString: string): ReactNode => {
    const kana = material as AdminKana;
    return (
      <>
        <td>{kana.id}</td>
        <td>{kana.level}</td>
        <td>{kana.kana}</td>
        <td style={{ whiteSpace: 'nowrap' }}>
          <Link to={`/admin/vocabs/${kana.vocab_id}`}>{kana.vocab_id}</Link>
        </td>
        <td>{kana.flashcard_material.vocab}</td>
        <td>{kana.flashcard_material.vocab_hiragana}</td>
        <td>{kana.flashcard_material.vocab_meaning}</td>
        <td>{kana.vocab?.source}</td>
        <td>
          {!!kana.flashcard_material.image_url && (
            <img width="200px" src={kana.flashcard_material.image_url} />
          )}
        </td>
        <td>
          <Link to={`/admin/kanas/${kana.id}?filters=${filterString}`} className="btn btn-outline-secondary btn-sm" target="_blank">View</Link>
        </td>
      </>
    );
  };

  return (
    <div className="container">
      <MaterialIndex materialType={'Kana'} getMaterialRow={getKanaRow} />
    </div>
  );
};

export default KanaIndex;
