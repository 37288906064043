import React, { useEffect } from 'react';
import { Outlet, Link, useSearchParams, useLocation } from 'react-router-dom';
import { colorClassFromLevel, loadTooltips } from '../common';
import { Course } from '../types';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import SubscribeButton from './SubscribeButton';

const Layout: React.FC = () => {
  const location = useLocation();

  const userQuery = useQuery({
    queryKey: ['user-profile'],
    queryFn: async () =>
      await axios
        .get('/api/users/profile')
        .then((res) => res.data),
  });
  const [searchParams] = useSearchParams();

  const course: Course | undefined = (() => {
    if (location.pathname.startsWith('/kanji')) return Course.Kanji;
    if (location.pathname.startsWith('/vocab')) return Course.Vocab;
    return undefined;
  })();

  const userLevel: undefined | number = course ? userQuery?.data?.level?.[course] || 1 : undefined;
  const subscriptionStatus = searchParams.get('qa_unsubscribed') ? 'inactive' : userQuery?.data?.subscription_status;

  const currentUpdateNonce = 3;
  const lastUpdateNonceKey = 'lastUpdateNonce';
  // If the user doesn't have the localStorage item, they must be new, so regard that as "no updates".
  const lastUpdateNonceValue = localStorage.getItem(lastUpdateNonceKey) ? Number(localStorage.getItem(lastUpdateNonceKey)) : currentUpdateNonce;
  const newUpdates = currentUpdateNonce - lastUpdateNonceValue;

  useEffect(() => {
    if (!localStorage.getItem(lastUpdateNonceKey)) {
      localStorage.setItem(lastUpdateNonceKey, String(currentUpdateNonce));
    }
  }, []);

  useEffect(loadTooltips, [userQuery?.data?.level, userQuery?.data?.subscription_status]);

  const courseName = course === Course.Kanji ? 'Kanji' : course === Course.Vocab ? 'Vocab' : '';
  const button = !!userLevel && <a href="#" className={`btn ${colorClassFromLevel(userLevel)}`} aria-current="page" data-bs-toggle="tooltip" data-bs-trigger="focus" data-bs-placement="bottom" title={userLevel < 9 ? `Get all level ${userLevel} cards to Intermediate to reach level ${userLevel + 1}!` : ''}><strong>{userQuery?.data ? courseName : ''}</strong> {userQuery?.data ? `${userLevel}` : 'loading...'}</a>;

  const handleUpdatesClick = (): void => {
    localStorage.setItem(lastUpdateNonceKey, String(currentUpdateNonce));
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container-fluid">
          <a href="/" className="me-md-4 mb-md-0 text-dark text-decoration-none">
            <span style={{ fontSize: '28px' }}>⛷️</span>
          </a>

          {!!course && (
            <>
              <ul className="navbar-nav me-md-4">
                <li className="nav-item">{button}</li>
              </ul>

              <ul className={`navbar-nav ${subscriptionStatus === 'inactive' ? 'me-md-4' : 'me-auto'}`}>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle text-dark" href="#" id="courseDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Change course
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="courseDropdown">
                    {course === Course.Kanji && <li><a className="dropdown-item" href="/vocab">Vocab (beta)</a></li>}
                    {course === Course.Vocab && <li><a className="dropdown-item" href="/kanji">Kanji</a></li>}
                    <li><a className="dropdown-item disabled" href="#">Grammar (coming soon)</a></li>
                  </ul>
                </li>
              </ul>
            </>
          )}

          {subscriptionStatus === 'inactive' && (
            <ul className="navbar-nav me-auto">
              <li className="nav-item">
                <SubscribeButton icon={'unlock'} />
              </li>
            </ul>
          )}

          <ul className="navbar-nav">
            <li className="nav-item me-md-4">
              <Link to="/updates" onClick={handleUpdatesClick} className={`nav-link ${newUpdates ? 'text-danger' : 'text-dark'}`}>
                <i className="bi bi-megaphone-fill"></i>
                {!!newUpdates && newUpdates > 0 && (<>{' '}{newUpdates}</>)}
              </Link>
            </li>

            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle text-dark" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i className="bi bi-person-circle"></i>
              </a>
              <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                <li><Link to="/settings" className="dropdown-item">Settings</Link></li>
                <li><Link to="mailto:help@kanji.ski" target="_blank" className="dropdown-item">Help</Link></li>
                <li><a className="dropdown-item" href="/users/sign_out">Logout</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>

      <Outlet />
    </div>
  );
};

export default Layout;
