import type { AdminReading, AdminMaterial, MaterialType, AdminKana } from './types';
import { formatMaterialSentence } from './common';

export const getFilteredMaterials = (materialType: MaterialType, materials: AdminMaterial[], filters: string[]): AdminMaterial[] => {
  let _filteredMaterials = materials;
  if (materialType === 'KanjiReading' || materialType === 'Vocab') {
    if (filters.includes('noUnderline')) {
      _filteredMaterials = _filteredMaterials.filter((material: AdminMaterial) => {
        const sentenceText = formatMaterialSentence(material.flashcard_material, material.level, false);
        return !sentenceText.includes('word-highlight');
      });
    }
    if (filters.includes('noSentence')) {
      _filteredMaterials = _filteredMaterials.filter((material: AdminMaterial) => !material.flashcard_material.sentence);
    }
    if (filters.includes('noBreakdown')) {
      _filteredMaterials = _filteredMaterials.filter((material: AdminMaterial) => !material.flashcard_material.sentence_breakdown);
    }
  }

  if (filters.includes('noImage')) {
    _filteredMaterials = _filteredMaterials.filter((material: AdminMaterial) => !material.image_url);
  }

  if (materialType === 'KanjiReading' || materialType === 'Kana') {
    if (filters.includes('hasVocabId')) {
      _filteredMaterials = _filteredMaterials.filter((material: AdminMaterial) => !!(material as AdminReading | AdminKana).vocab_id);
    }
    if (filters.includes('noVocabId')) {
      _filteredMaterials = _filteredMaterials.filter((material: AdminMaterial) => !(material as AdminReading | AdminKana).vocab_id);
    }
  }

  if (filters.includes('noAudio')) {
    _filteredMaterials = _filteredMaterials.filter((material: AdminMaterial) => !material.audio_url);
  }
  if (filters.includes('needsAudioQA')) {
    _filteredMaterials = _filteredMaterials.filter((material: AdminMaterial) => material.audio_url && !material.is_audio_approved);
  }
  if (filters.includes('needsImageQA')) {
    _filteredMaterials = _filteredMaterials.filter((material: AdminMaterial) => material.image_url && !material.is_image_approved);
  }
  if (filters.includes('noImageSituation')) {
    _filteredMaterials = _filteredMaterials.filter((material: AdminMaterial) => !material.image_situation);
  }
  if (filters.includes('noImageDescription')) {
    _filteredMaterials = _filteredMaterials.filter((material: AdminMaterial) => !material.image_description);
  }
  return _filteredMaterials;
};
