import React, { useEffect, useState, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useParams, Link, useNavigate, useSearchParams } from 'react-router-dom';
import type { AdminReading, Flashcard } from '../../types';
import { Course } from '../../types';
import { getFlashcard } from '../../actions';
import { getFilteredReadings } from '../../admin';
import FlashcardCard from '../FlashcardCard';
import type { MouseEvent } from 'react';

const ReadingDetails: React.FC = () => {
  /* Hooks */

  const [reading, setReading] = useState<AdminReading | undefined>(undefined);
  const [gradeValue, setGradeValue] = useState<string>('');
  const [vocabIdValue, setVocabIdValue] = useState<string>('');
  const [sentenceImageSituationValue, setSentenceImageSituationValue] = useState<string>('');
  const [sentenceImageDescriptionValue, setSentenceImageDescriptionValue] = useState<string>('');
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [flashcard, setFlashcard] = useState<Flashcard | undefined>(undefined);
  const [prevReading, setPrevReading] = useState<AdminReading | undefined>(undefined);
  const [nextReading, setNextReading] = useState<AdminReading | undefined>(undefined);
  const [readingIndex, setReadingIndex] = useState<number | undefined>(undefined);
  const [readingCount, setReadingCount] = useState<number | undefined>(undefined);
  const [isVocabAlreadyUsed, setIsVocabAlreadyUsed] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>(localStorage.getItem('adminReadingTabDefault') ?? 'Flashcard');

  const { id: readingId } = useParams();

  const navigate = useNavigate();

  const audioRef = useRef<HTMLAudioElement | null>(null);

  const readingQuery = useQuery({
    queryKey: ['admin', 'reading', readingId],
    queryFn: async () =>
      await axios
        .get(`/api/admin/readings/${readingId}`)
        .then((res) => res.data),
  });

  const readingsQuery = useQuery({
    queryKey: ['admin', 'readings'],
    queryFn: async () =>
      await axios
        .get(`/api/admin/readings?level=${reading?.level}`)
        .then((res) => res.data),
    enabled: !!reading
  });

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const sameVocabReadingIds = readingQuery?.data?.same_vocab_reading_ids;
    if (sameVocabReadingIds?.length && reading?.id) {
      const isVocabAlreadyUsedOnAnotherKanjiReading = !sameVocabReadingIds.includes(reading.id) || sameVocabReadingIds.length > 1;
      setIsVocabAlreadyUsed(isVocabAlreadyUsedOnAnotherKanjiReading);
    } else {
      setIsVocabAlreadyUsed(false);
    }
  }, [(readingQuery?.data?.same_vocab_reading_ids || []).join(','), reading?.id]);

  useEffect(() => {
    if (reading?.id && readingsQuery?.data?.readings) {
      const readings = readingsQuery.data.readings as AdminReading[];
      let filteredReadings = readings;
      const filtersString = searchParams.get('filters');
      if (filtersString) {
        filteredReadings = getFilteredReadings(readings, filtersString.split('+'));
      }
      const index = filteredReadings.findIndex((_reading: AdminReading) => _reading.id === reading.id);
      setReadingIndex(index as number | undefined);
      setReadingCount(filteredReadings.length);
      setPrevReading(index > 0 ? filteredReadings[index - 1] : undefined);
      setNextReading(index < filteredReadings.length - 1 ? filteredReadings[index + 1] : undefined);
    }
  }, [reading?.id, !!readingsQuery?.data?.readings, searchParams.get('filters')]);

  useEffect(() => {
    if (readingId) {
      void readingQuery.refetch();
    }
  }, [readingId]);

  useEffect(() => {
    playAudio();
  }, [readingId, flashcard?.material.audio_url, audioRef.current?.src]);

  useEffect(() => {
    const _reading = readingQuery?.data?.reading;
    if (_reading) {
      setReading(_reading as AdminReading);
      setGradeValue((_reading.grade as string) || '');
      setVocabIdValue((_reading.vocab_id as string) || '');
      setSentenceImageSituationValue((_reading.image_situation as string) || '');
      setSentenceImageDescriptionValue((_reading.image_description as string) || '');
      void (async () => {
        const { flashcard: _flashcard } = await getFlashcard(Course.Kanji, 'KanjiReading', Number(readingId));
        setFlashcard(_flashcard);
      })()
    }
  }, [JSON.stringify(readingQuery?.data)]);

  // Use useEffect to add the event listener
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    JSON.stringify(reading),
    prevReading?.id,
    nextReading?.id,
    gradeValue,
    vocabIdValue,
  ]);

  /* Handlers */

  // Function to handle the key press event
  const handleKeyDown = (event: KeyboardEvent): void => {
    const activeElement = document.activeElement;
    if (
      activeElement instanceof HTMLInputElement ||
      activeElement instanceof HTMLTextAreaElement ||
      (activeElement && activeElement.getAttribute('contenteditable') === 'true')
    ) {
      // If the user is typing in an input or textarea, do nothing
      return;
    }

    if (event.key === 'ArrowRight' && nextReading?.id) {
      void (async () => {
        if (hasChanges()) {
          const result = confirm('Do you want to save?');
          if (result) {
            await handleSave();
          }
        }
        navigate(`/admin/readings/${nextReading.id}?filters=${searchParams.get('filters')}`);
      })();
    } else if (event.key === 'ArrowLeft' && prevReading?.id) {
      navigate(`/admin/readings/${prevReading.id}?filters=${searchParams.get('filters')}`);
    }
  };

  const handleGradeChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setGradeValue(event.target.value);
  };

  const handleVocabIdChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setVocabIdValue(event.target.value);
  };

  const handleSentenceImageSituationChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setSentenceImageSituationValue(event.target.value);
  };

  const handleSentenceImageDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setSentenceImageDescriptionValue(event.target.value);
  };

  const handleSave = async (): Promise<void> => {
    // Any additional state referenced
    // here has to be added to the dependencies
    // of the useEffect function that listens
    // for key presses.
    const data: Partial<AdminReading> = {
      grade: gradeValue,
      vocab_id: Number(vocabIdValue) || null,
    };
    setIsUpdating(true);
    await axios.patch(
      `/api/admin/readings/${reading?.id}`,
      {
        reading: data,
        sentence: {
          image_situation: sentenceImageSituationValue || null,
          image_description: sentenceImageDescriptionValue || null,
        }
      },
      {
        headers: {
          'Content-Type': 'application/json', // Tells Rails it's a JSON request
          Accept: 'application/json', // Ensures Rails responds with JSON
        }
      }
    );
    await readingQuery.refetch();
    setIsUpdating(false);
  };

  const handleFetchImageClick = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();
    void handleAction('fetch_image');
  };

  const handleFetchImageSituationClick = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();
    void handleAction('fetch_image_situation');
  };

  const handleFetchImageDescriptionClick = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();
    void handleAction('fetch_image_description');
  };

  const handleUploadImageClick = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();
    void handleAction('upload_image');
  };

  const handleApproveImageClick = (event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    void handleAction('approve_image');
  };

  const handleUnapproveImageClick = (event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    void handleAction('unapprove_image');
  };

  const handleApproveAudioClick = (event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    void handleAction('approve_audio');
  };

  const handleUnapproveAudioClick = (event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    void handleAction('unapprove_audio');
  };

  const handleAction = async (action: string): Promise<void> => {
    setIsUpdating(true);
    await axios.post(
      `/api/admin/readings/${reading?.id}/actions`,
      { actions: { [action]: true } },
      {
        headers: {
          'Content-Type': 'application/json', // Tells Rails it's a JSON request
          Accept: 'application/json', // Ensures Rails responds with JSON
        }
      }
    );
    await readingQuery.refetch();
    setIsUpdating(false);
  };

  const handleTabClick = (event: MouseEvent<HTMLAnchorElement>, tabName: string): void => {
    event.preventDefault();
    localStorage.setItem('adminReadingTabDefault', tabName);
    setActiveTab(tabName);
  };

  const hasChanges = (): boolean => {
    if (!reading) {
      return false;
    }
    return gradeValue !== (reading.grade || '') ||
      vocabIdValue !== (reading.vocab_id || '');
  };

  const playAudio = (): void => {
    if (audioRef.current) {
      void audioRef.current.play();
    }
  };

  if (readingQuery.isLoading || readingsQuery.isLoading || !reading) return 'Loading...';

  const audioUrl = flashcard?.material.audio_url;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4">
          <p>
            <Link to={`/admin/readings/${prevReading?.id}?filters=${searchParams.get('filters')}`} className={`btn btn-outline-secondary btn-sm ${isUpdating || !prevReading?.id ? 'disabled' : ''}`}>Previous</Link>{' '}
            <Link to={`/admin/readings/${nextReading?.id}?filters=${searchParams.get('filters')}`} className={`btn btn-outline-secondary btn-sm ${isUpdating || !nextReading?.id ? 'disabled' : ''}`}>Next</Link>{' '}
            {readingIndex === undefined ? '' : readingIndex + 1} / {readingCount}
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <h2>{reading.kanji} [{reading.reading}]</h2>
          <table className="table table-borderless">
            <tbody>
              <tr>
                <th>ID</th>
                <td>{reading.id}</td>
              </tr>
              <tr>
                <th>Level</th>
                <td>{reading.level}</td>
              </tr>
              <tr>
                <th>Grade</th>
                <td>
                  <select className="form-select" value={gradeValue} disabled={isUpdating} onChange={handleGradeChange}>
                    <option value="elementary">Elementary</option>
                    <option value="middle">Middle</option>
                    <option value="high">High</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>Vocab ID</th>
                <td>
                  <select className={`form-select ${vocabIdValue && isVocabAlreadyUsed ? 'is-invalid' : ''}`} value={vocabIdValue} disabled={isUpdating || !readingQuery.data.vocab_id_options.length} onChange={handleVocabIdChange}>
                    <option value={''}></option>
                    {readingQuery.data.vocab_id_options.map((idText: any) => (
                      <option key={idText[0]} value={idText[0]}>{idText[1]}</option>
                    ))}
                  </select>
                  {vocabIdValue && <Link to={`/admin/vocabs/${vocabIdValue}`}>{vocabIdValue}</Link>}
                  {vocabIdValue && isVocabAlreadyUsed &&
                    <div id="validationReadingVocabIdFeedback" className="invalid-feedback">
                      This vocab is already in use.{' '}
                      {readingQuery.data.same_vocab_reading_ids.map((_id: number) => (
                        <>
                          <Link key={_id} to={`/admin/readings/${_id}`}>{_id}</Link>{' '}
                        </>
                      ))}
                    </div>
                  }
                </td>
              </tr>

              <tr>
                <th>Image Situation</th>
                <td>
                  <textarea
                    id="readingImageSituation"
                    className="form-control"
                    disabled={isUpdating || !vocabIdValue}
                    value={sentenceImageSituationValue}
                    onChange={handleSentenceImageSituationChange}
                    rows={3}
                  />
                </td>
              </tr>
              <tr>
                <th>Image Descripion</th>
                <td>
                  <textarea
                    id="readingImageDescription"
                    className="form-control"
                    disabled={isUpdating || !vocabIdValue}
                    value={sentenceImageDescriptionValue}
                    onChange={handleSentenceImageDescriptionChange}
                    rows={5}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="col-md-4">
          <button type="button" className={`btn btn-primary ${isUpdating ? 'disabled' : ''}`} onClick={() => { void handleSave() }}>Save</button>
          {vocabIdValue && (
            <>
            <button type="button" className={`ms-2 btn btn-outline-secondary ${isUpdating ? 'disabled' : ''}`} onClick={(event) => { void handleFetchImageSituationClick(event) }}>Fetch image situation</button>
            <button type="button" className={`ms-2 btn btn-outline-secondary ${isUpdating ? 'disabled' : ''}`} onClick={(event) => { void handleFetchImageDescriptionClick(event) }}>Fetch image description</button>
            </>
          )}
          <button type="button" className={`ms-2 btn btn-outline-secondary ${isUpdating ? 'disabled' : ''}`} onClick={(event) => { void handleFetchImageClick(event) }}>Fetch image</button>

          <hr />

          <h3>DALLE raw image</h3>
          {!!reading.image_tmp && (
            <>
              <p><button type="button" className="btn btn-outline-secondary btn-sm" onClick={(event) => { void handleUploadImageClick(event) }}>Upload</button></p>
              <div className="mt-4 card">
                <div className="card-body">
                  <img width="100%" src={reading.image_tmp} />
                </div>
              </div>
            </>
          )}

          <h3>Audio</h3>
          {!!audioUrl && (
            <div>
              <audio ref={audioRef} src={audioUrl} />
              <button className="btn btn-outline-secondary btn-sm me-2" onClick={playAudio}>Play</button>
              {reading.vocab_id && (flashcard?.material?.is_audio_approved ? <button className="btn btn-danger btn-sm" type="button" onClick={handleUnapproveAudioClick}>Unapprove</button> : <button className="btn btn-success btn-sm" type="button" onClick={handleApproveAudioClick}>Approve</button>)}
            </div>
          )}

          <h3 className="mt-4">Image</h3>
          <form
            action={`/api/admin/readings/${reading?.id}`}
            method="POST"
            encType="multipart/form-data"
          >
            <input type="hidden" name="_method" value="patch" />
            <input type="file" id="reading_image" name="reading[image_file]" accept="image/*" />
            <button className="btn btn-outline-secondary btn-sm" type="submit">Upload</button>
          </form>
        </div>

        <div className="col-md-4">
          <ul className="nav nav-tabs">
            {['Flashcard', 'Flashcard (back)', 'Image'].map((tabName: string) => (
              <li key={tabName} className="nav-item">
                <a className={`nav-link ${activeTab === tabName ? 'active' : ''}`} href="#" onClick={(event) => { handleTabClick(event, tabName) }}>{tabName}</a>
              </li>
            ))}
          </ul>

          {activeTab === 'Flashcard' && (
            <div className="mt-4 card">
              <div className="card-body">
                {!!flashcard && <FlashcardCard flashcard={flashcard} userSubscribed={false} flipped={false} loading={false} onFlip={() => {}} onNext={() => {}} settings={{}} />}
              </div>
            </div>
          )}

          {activeTab === 'Flashcard (back)' && (
            <div className="mt-4 card">
              <div className="card-body">
                {!!flashcard && <FlashcardCard flashcard={flashcard} userSubscribed={false} flipped={true} loading={false} onFlip={() => {}} onNext={() => {}} settings={{}} />}
              </div>
            </div>
          )}

          {activeTab === 'Image' && flashcard?.material?.image_url && (
            <div className="mt-4 card">
              <div className="card-body">
                {reading.vocab_id && (flashcard.material.is_image_approved ? <button className="btn btn-danger btn-sm" type="button" onClick={handleUnapproveImageClick}>Unapprove</button> : <button className="btn btn-success btn-sm" type="button" onClick={handleApproveImageClick}>Approve</button>)}
                <img width="100%" src={flashcard.material.image_url} />
                <p>
                  {flashcard.material.sentence}<br/>
                  {flashcard.material.sentence_meaning}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReadingDetails;
