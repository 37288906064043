import React, { useEffect, useState, useRef } from 'react';
import type { MouseEvent } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useParams, Link, useNavigate, useSearchParams } from 'react-router-dom';
import type { AdminVocab, Flashcard, SentenceBreakdownPart } from '../../types';
import { Course } from '../../types';
import { getFlashcard } from '../../actions';
import { getFilteredVocabs } from '../../admin';
import FlashcardCard from '../FlashcardCard';

const VocabDetails: React.FC = () => {
  /* Hooks */

  const [vocab, setVocab] = useState<AdminVocab | undefined>(undefined);
  const [vocabKanjiValue, setVocabKanjiValue] = useState<string>('');
  const [vocabReadingValue, setVocabReadingValue] = useState<string>('');
  const [vocabDefinitionValue, setVocabDefinitionValue] = useState<string>('');
  const [sentenceTextValue, setSentenceTextValue] = useState<string>('');
  const [sentenceTranslationValue, setSentenceTranslationValue] = useState<string>('');
  const [sentenceBreakdownValue, setSentenceBreakdownValue] = useState<string>('');
  const [sentenceAudioSpecValue, setSentenceAudioSpecValue] = useState<string>('');
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [flashcard, setFlashcard] = useState<Flashcard | undefined>(undefined);
  const [prevVocab, setPrevVocab] = useState<AdminVocab | undefined>(undefined);
  const [nextVocab, setNextVocab] = useState<AdminVocab | undefined>(undefined);
  const [vocabIndex, setVocabIndex] = useState<number | undefined>(undefined);
  const [vocabCount, setVocabCount] = useState<number | undefined>(undefined);

  const { id: vocabId } = useParams();

  const navigate = useNavigate();

  const audioRef = useRef<HTMLAudioElement | null>(null);

  const vocabQuery = useQuery({
    queryKey: ['admin', 'vocab', vocabId],
    queryFn: async () =>
      await axios
        .get(`/api/admin/vocabs/${vocabId}`)
        .then((res) => res.data),
  });

  const vocabsQuery = useQuery({
    queryKey: ['admin', 'vocabs'],
    queryFn: async () =>
      await axios
        .get(`/api/admin/vocabs?level=${vocab?.level}`)
        .then((res) => res.data),
    enabled: !!vocab
  });

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (vocab?.id && vocabsQuery?.data?.vocabs) {
      const vocabs = vocabsQuery.data.vocabs as AdminVocab[];
      let filteredVocabs = vocabs;
      const filtersString = searchParams.get('filters');
      if (filtersString) {
        filteredVocabs = getFilteredVocabs(vocabs, filtersString.split('+'));
      }
      const index = filteredVocabs.findIndex((_vocab: AdminVocab) => _vocab.id === vocab.id);
      setVocabIndex(index as number | undefined);
      setVocabCount(filteredVocabs.length);
      setPrevVocab(index > 0 ? filteredVocabs[index - 1] : undefined);
      setNextVocab(index < filteredVocabs.length - 1 ? filteredVocabs[index + 1] : undefined);
    }
  }, [vocab?.id, !!vocabsQuery?.data?.vocabs, searchParams.get('filters')]);

  useEffect(() => {
    if (vocabId) {
      void vocabQuery.refetch();
    }
  }, [vocabId]);

  useEffect(() => {
    playAudio();
  }, [vocabId, audioRef.current?.src]);

  useEffect(() => {
    const _vocab = vocabQuery?.data?.vocab;
    if (_vocab) {
      setVocab(_vocab as AdminVocab);
      setVocabKanjiValue((_vocab.kanji as string) || '');
      setVocabReadingValue((_vocab.reading as string) || '');
      setVocabDefinitionValue((_vocab.definition as string) || '');
      setSentenceTextValue(vocabQuery?.data?.sentence?.text as string || '');
      setSentenceTranslationValue(vocabQuery?.data?.sentence?.translation as string || '');
      setSentenceBreakdownValue(vocabQuery?.data?.sentence?.breakdown as string || '');
      setSentenceAudioSpecValue(vocabQuery?.data?.sentence?.audio_spec as string || '');
      void (async () => {
        const { flashcard: _flashcard } = await getFlashcard(Course.Vocab, 'Vocab', Number(vocabId));
        setFlashcard(_flashcard);
      })()
    }
  }, [JSON.stringify(vocabQuery?.data)]);

  // Use useEffect to add the event listener
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    JSON.stringify(vocab),
    prevVocab?.id,
    nextVocab?.id,
    vocabKanjiValue,
    vocabReadingValue,
    vocabDefinitionValue,
    sentenceTextValue,
    sentenceTranslationValue,
    sentenceBreakdownValue,
    sentenceAudioSpecValue,
  ]);

  /* Handlers */

  // Function to handle the key press event
  const handleKeyDown = (event: KeyboardEvent): void => {
    const activeElement = document.activeElement;
    if (
      activeElement instanceof HTMLInputElement ||
      activeElement instanceof HTMLTextAreaElement ||
      (activeElement && activeElement.getAttribute('contenteditable') === 'true')
    ) {
      // If the user is typing in an input or textarea, do nothing
      return;
    }

    if (event.key === 'ArrowRight' && nextVocab?.id) {
      void (async () => {
        if (hasChanges()) {
          const result = confirm('Do you want to save?');
          if (result) {
            await handleSave();
          }
        }
        navigate(`/admin/vocabs/${nextVocab.id}?filters=${searchParams.get('filters')}`);
      })();
    } else if (event.key === 'ArrowLeft' && prevVocab?.id) {
      navigate(`/admin/vocabs/${prevVocab.id}?filters=${searchParams.get('filters')}`);
    }
  };

  const handleVocabKanjiChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setVocabKanjiValue(event.target.value);
  };

  const handleVocabReadingChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setVocabReadingValue(event.target.value);
  };

  const handleVocabDefinitionChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setVocabDefinitionValue(event.target.value);
  };

  const handleSentenceTextChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSentenceTextValue(event.target.value);
  };

  const handleSentenceTranslationChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSentenceTranslationValue(event.target.value);
  };

  const handleSentenceBreakdownChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setSentenceBreakdownValue(event.target.value);
  };

  const handleSentenceAudioSpecChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setSentenceAudioSpecValue(event.target.value);
  };

  const handleSave = async (): Promise<void> => {
    // Any additional state referenced
    // here has to be added to the dependencies
    // of the useEffect function that listens
    // for key presses.
    const data: { vocab: Partial<AdminVocab>, sentence: any } = {
      vocab: {
        kanji: vocabKanjiValue,
        reading: vocabReadingValue,
        definition: vocabDefinitionValue,
      },
      sentence: {
        text: sentenceTextValue || undefined,
        translation: sentenceTranslationValue || undefined,
        breakdown: sentenceBreakdownValue || undefined,
        audio_spec: sentenceAudioSpecValue || undefined,
      },
    };
    setIsUpdating(true);
    await axios.patch(
      `/api/admin/vocabs/${vocab?.id}`,
      data,
      {
        headers: {
          'Content-Type': 'application/json', // Tells Rails it's a JSON request
          Accept: 'application/json', // Ensures Rails responds with JSON
        }
      }
    );
    await vocabQuery.refetch();
    setIsUpdating(false);
  };

  const handleFetchAudioClick = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();
    void handleSentenceAction('fetch_audio');
  };

  const handleFetchImageClick = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();
    void handleSentenceAction('fetch_image');
  };

  const handleFetchBreakdownClick = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();
    void handleSentenceAction('fetch_breakdown');
  };

  const handleUploadImageClick = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();
    void handleSentenceAction('upload_image');
  };

  const handleApproveImageClick = (event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    void handleSentenceAction('approve_image');
  };

  const handleSentenceAction = async (action: string): Promise<void> => {
    setIsUpdating(true);
    await axios.post(
      `/api/admin/vocabs/${vocab?.id}/actions`,
      { sentence_actions: { [action]: true } },
      {
        headers: {
          'Content-Type': 'application/json', // Tells Rails it's a JSON request
          Accept: 'application/json', // Ensures Rails responds with JSON
        }
      }
    );
    await vocabQuery.refetch();
    setIsUpdating(false);
  };

  const handleSetBreakdownVocab = (index: number): void => {
    const newBreakdown = { ...JSON.parse(sentenceBreakdownValue) };
    newBreakdown.breakdown.forEach((bp: SentenceBreakdownPart, bpi: number) => {
      if (bpi === index) {
        newBreakdown.breakdown[bpi] = { ...bp, vocab: true };
      } else {
        newBreakdown.breakdown[bpi] = { word: bp.word, pronunciation: bp.pronunciation, meaning: bp.meaning };
      }
    });
    setSentenceBreakdownValue(JSON.stringify(newBreakdown));
  };

  const handleSetPhoneme = (index: number): void => {
    const items = JSON.parse(sentenceBreakdownValue).breakdown as SentenceBreakdownPart[];
    const foreward = items.slice(0, index).map((bp: SentenceBreakdownPart) => bp.word).join('');
    const afterward = items.slice(index + 1).map((bp: SentenceBreakdownPart) => bp.word).join('');
    setSentenceAudioSpecValue(`<speak>${foreward}<phoneme alphabet="x-amazon-yomigana" ph="${items[index].pronunciation}">${items[index].word}</phoneme>${afterward}</speak>`);
  };

  const hasChanges = (): boolean => {
    if (!vocab) {
      return false;
    }
    return vocabKanjiValue !== (vocab.kanji || '') ||
      vocabReadingValue !== (vocab.reading || '') ||
      vocabDefinitionValue !== (vocab.definition || '');
  };

  const playAudio = (): void => {
    if (audioRef.current) {
      void audioRef.current.play();
    }
  };

  if (vocabQuery.isLoading || vocabsQuery.isLoading || !vocab) return 'Loading...';

  const sentence = vocabQuery?.data?.sentence;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4">
          <p>
            <Link to={`/admin/vocabs/${prevVocab?.id}?filters=${searchParams.get('filters')}`} className={`btn btn-outline-secondary btn-sm ${isUpdating || !prevVocab?.id ? 'disabled' : ''}`}>Previous</Link>{' '}
            <Link to={`/admin/vocabs/${nextVocab?.id}?filters=${searchParams.get('filters')}`} className={`btn btn-outline-secondary btn-sm ${isUpdating || !nextVocab?.id ? 'disabled' : ''}`}>Next</Link>{' '}
            {vocabIndex === undefined ? '' : vocabIndex + 1} / {vocabCount}
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <h2>{vocab.kanji} [{vocab.reading}]</h2>
          <table className="table table-borderless">
            <tbody>
              <tr>
                <th>ID</th>
                <td>{vocab.id}</td>
              </tr>
              <tr>
                <th>Level</th>
                <td>{vocab.level}</td>
              </tr>
              <tr>
                <th>Vocab Kanji</th>
                <td>
                  <input type="text" className="form-control" id="vocabKanji" disabled={isUpdating} value={vocabKanjiValue} onChange={handleVocabKanjiChange} />
                </td>
              </tr>
              <tr>
                <th>Vocab Reading</th>
                <td>
                  <input type="text" className="form-control" id="vocabReading" disabled={isUpdating} value={vocabReadingValue} onChange={handleVocabReadingChange} />
                </td>
              </tr>
              <tr>
                <th>Vocab Definition</th>
                <td>
                  <input type="text" className="form-control" id="vocabDefinition" disabled={isUpdating} value={vocabDefinitionValue} onChange={handleVocabDefinitionChange} />
                </td>
              </tr>
              <tr>
                <th>Sentence ID</th>
                <td>
                  {sentence?.id}
                </td>
              </tr>
              <tr>
                <th>Sentence Text</th>
                <td>
                  <input type="text" className="form-control" id="sentenceText" disabled={isUpdating} value={sentenceTextValue} onChange={handleSentenceTextChange} />
                </td>
              </tr>
              <tr>
                <th>Sentence Clean</th>
                <td>
                  {sentence?.clean_text}
                </td>
              </tr>
              <tr>
                <th>Sentence Translation</th>
                <td>
                  <input type="text" className="form-control" id="sentenceText" disabled={isUpdating} value={sentenceTranslationValue} onChange={handleSentenceTranslationChange} />
                </td>
              </tr>
              <tr>
                <th>Sentence Breakdown</th>
                <td>
                  <textarea
                    id="sentenceBreakdown"
                    className="form-control"
                    disabled={isUpdating}
                    value={sentenceBreakdownValue}
                    onChange={handleSentenceBreakdownChange}
                    rows={10}
                  />
                </td>
              </tr>
              <tr>
                <th>Sentence Audio Spec</th>
                <td>
                  <textarea
                    id="sentenceAudioSpec"
                    className="form-control"
                    disabled={isUpdating}
                    value={sentenceAudioSpecValue}
                    onChange={handleSentenceAudioSpecChange}
                    rows={10}
                  />
                  <p style={{ fontSize: '9px' }}><pre><code>{'<speak>\n<phoneme alphabet="x-amazon-yomigana" ph="あした">明日</phoneme>\n</speak>'}</code></pre></p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="col-md-4">
          <button type="button" className={`btn btn-primary ${isUpdating ? 'disabled' : ''}`} onClick={() => { void handleSave() }}>Save</button>
          <button type="button" className={`ms-2 btn btn-outline-secondary ${isUpdating ? 'disabled' : ''}`} onClick={(event) => { void handleFetchAudioClick(event) }}>Fetch audio</button>
          <button type="button" className={`ms-2 btn btn-outline-secondary ${isUpdating ? 'disabled' : ''}`} onClick={(event) => { void handleFetchImageClick(event) }}>Fetch image</button>
          <button type="button" className={`ms-2 btn btn-outline-secondary ${isUpdating ? 'disabled' : ''}`} onClick={(event) => { void handleFetchBreakdownClick(event) }}>Fetch breakdown</button>

          <hr />

          <h3>Audio</h3>
          {!!sentence?.audio_url && (
            <div>
              <audio ref={audioRef} src={sentence.audio_url} />
              <button onClick={playAudio}>Play</button>
            </div>
          )}

          {!!sentenceBreakdownValue && (
            <>
              <h3>Sentence Breakdown</h3>
              <table className="table">
                <tr>
                  <th>Word</th>
                  <th>Pronunciation</th>
                  <th>Meaning</th>
                  <th>Vocab</th>
                </tr>
                {JSON.parse(sentenceBreakdownValue).breakdown.map((bp: SentenceBreakdownPart, index: number) => (
                  <tr key={index}>
                    <td>{bp.word}</td>
                    <td>{bp.pronunciation}</td>
                    <td>{bp.meaning}</td>
                    <td>
                      {bp.vocab ? 'true' : <button type="button" style={{ padding: 0 }} className="btn btn-sm btn-outline-secondary" onClick={() => { handleSetBreakdownVocab(index) }}>Vocab</button>}
                      <button type="button" style={{ padding: 0 }} className="btn btn-sm btn-outline-secondary" onClick={() => { handleSetPhoneme(index) }}>Phoneme</button>
                    </td>
                  </tr>
                ))}
              </table>
            </>
          )}

          {!!sentence?.image_tmp && (
            <>
              <h3>DALL-E raw image</h3>
              <p><button type="button" className="btn btn-outline-secondary btn-sm" onClick={(event) => { void handleUploadImageClick(event) }}>Upload</button></p>
              <div className="mt-4 card">
                <div className="card-body">
                  <img width="100%" src={sentence.image_tmp} />
                </div>
              </div>
            </>
          )}
        </div>

        <div className="col-md-4">
          <h3>Flashcard preview</h3>
          <div className="mt-4 card">
            <div className="card-body">
              {!!flashcard && <FlashcardCard flashcard={flashcard} userSubscribed={false} flipped={false} loading={false} onFlip={() => {}} onNext={() => {}} settings={{ vocab_prompt: 'audio-text' }} />}
            </div>
          </div>

          <div className="mt-4 card">
            <div className="card-body">
              {!!flashcard && <FlashcardCard flashcard={flashcard} userSubscribed={false} flipped={true} loading={false} onFlip={() => {}} onNext={() => {}} settings={{ vocab_prompt: 'audio-text' }} />}
            </div>
          </div>

          {sentence?.image_url && (
            <div className="mt-4 card">
              <div className="card-body">
                {sentence.is_image_approved ? <>approved</> : <button className="btn btn-outline-secondary btn-sm" type="button" onClick={handleApproveImageClick}>Approve</button>}
                <img width="100%" src={sentence.image_url} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VocabDetails;
