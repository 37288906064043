import React, { useEffect, useState } from 'react';
import { flushSync } from 'react-dom';
import type { MouseEvent } from 'react';
import axios from 'axios';
import type { AdminReading, AdminReadingFilters, AdminReadingActions } from '../../types';
import { getFilteredReadings } from '../../admin';
import { useSearchParams, Link } from 'react-router-dom';

const ReadingsIndex: React.FC = () => {
  /* Hooks */

  const [readings, setReadings] = useState<AdminReading[]>([]);
  const [filteredReadings, setFilteredReadings] = useState<AdminReading[]>([]);
  const [level, setLevel] = useState<string | undefined>(undefined);
  const [filters, setFilters] = useState<AdminReadingFilters>({
    noImage: false,
    noSentence: false,
    noUnderline: false,
    hasVocabId: false,
    noVocabId: false,
    noAudio: false,
    needsImageQA: false,
    needsAudioQA: false,
    noBreakdown: false,
    noImageSituation: false,
    noImageDescription: false,
  });
  const [activeFetchActions, setActiveFetchActions] = useState<AdminReadingActions>({});
  const [bulkFetchNum, setBulkFetchNum] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    void (async () => {
      if (!level) {
        return;
      }

      flushSync(() => { setIsLoading(true) });
      const res = await axios.get(`/api/admin/readings?level=${level}`);
      setReadings(res.data.readings as AdminReading[]);
    })();
  }, [level]);

  useEffect(() => {
    if (searchParams.get('level')) {
      setLevel(searchParams.get('level') as string);
    } else {
      setLevel('1');
    }
  }, [searchParams.get('level')]);

  useEffect(() => {
    if (level) {
      setFilteredReadings(getFilteredReadings(readings, activeFilters()));
      setIsLoading(false);
    }
  }, [JSON.stringify(readings), JSON.stringify(filters)]);

  /* Handlers */

  const handleLevelChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const newLevel = event.target.value;
    setLevel(newLevel);
    const url = new URL(window.location.href);
    url.searchParams.set('level', newLevel); // Add or update the 'url' query parameter
    window.history.pushState({}, '', url); // Update the browser's URL without reloading
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>, filterName: string): void => {
    setFilters((prev) => ({ ...prev, [filterName]: event.target.checked }));
  };

  const handleFetchButtonClick = async (event: MouseEvent<HTMLButtonElement>, key: keyof AdminReadingActions): Promise<void> => {
    event.preventDefault();
    await fetchBulk({ [key]: true });
  };

  const fetchBulk = async (actions: AdminReadingActions): Promise<void> => {
    setActiveFetchActions(actions);
    try {
      const _filteredReadings = [...filteredReadings];
      let index = 0;
      for (const reading of _filteredReadings) {
        setBulkFetchNum(index + 1);
        const updatedReading = await fetchActionsWithRetries(reading.id, actions);
        if (updatedReading) {
          setFilteredReadings((prevItems) => {
            return prevItems.map((item) => (item.id === reading.id ? updatedReading : item));
          });
        }
        index += 1;
      }
    } catch (e) {
      console.error(e);
    } finally {
      setBulkFetchNum(0);
      setActiveFetchActions({});
    }
  };

  const fetchActionsWithRetries = async (readingId: number, actions: AdminReadingActions): Promise<AdminReading | undefined> => {
    let attempts = 0;
    const maxRetries = 3;
    while (attempts < maxRetries) {
      try {
        const result = await axios.post(
          `/api/admin/readings/${readingId}/actions`,
          { actions },
          {
            headers: {
              'Content-Type': 'application/json', // Tells Rails it's a JSON request
              Accept: 'application/json', // Ensures Rails responds with JSON
            }
          }
        );
        return result.data;
      } catch (error) {
        attempts++;
        if (attempts >= maxRetries) {
          throw error; // Max retries reached, rethrow the error
        }
        console.log(`Retrying... (${attempts}/${maxRetries})`);
        await new Promise((resolve) => setTimeout(resolve, 5000)); // Wait before retrying
      }
    }
  };

  const activeFilters = (): string[] => {
    return Object.keys(filters).filter((key) => !!filters[key as keyof AdminReadingFilters]);
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-2">
          <select className="form-select" disabled={isLoading} value={level} onChange={handleLevelChange}>
            <option value="1">1 (10級)</option>
            <option value="2">2 (9級)</option>
            <option value="3">3 (8級)</option>
            <option value="4">4 (7級)</option>
            <option value="5">5 (6級)</option>
            <option value="6">6 (5級)</option>
            <option value="7">7 (4級)</option>
            <option value="8">8 (3級)</option>
            <option value="9">9 (2級)</option>
          </select>
        </div>

        <div className="col-md-2">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="filterNoSentence"
              checked={filters.noSentence}
              onChange={(event) => { handleFilterChange(event, 'noSentence') }}
              disabled={isLoading}
            />
            <label className="form-check-label" htmlFor="filterNoSentence">
              no sentence
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="filterNoBreakdown"
              checked={filters.noBreakdown}
              onChange={(event) => { handleFilterChange(event, 'noBreakdown') }}
              disabled={isLoading}
            />
            <label className="form-check-label" htmlFor="filterNoBreakdown">
              no breakdown
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="filterNoImage"
              checked={filters.noImage}
              onChange={(event) => { handleFilterChange(event, 'noImage') }}
              disabled={isLoading}
            />
            <label className="form-check-label" htmlFor="filterNoImage">
              no image
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="filterNoUnderline"
              checked={filters.noUnderline}
              onChange={(event) => { handleFilterChange(event, 'noUnderline') }}
              disabled={isLoading}
            />
            <label className="form-check-label" htmlFor="filterNoUnderline">
              no vocab underline
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="filterHasVocabId"
              checked={filters.hasVocabId}
              onChange={(event) => { handleFilterChange(event, 'hasVocabId') }}
              disabled={isLoading}
            />
            <label className="form-check-label" htmlFor="filterHasVocabId">
              has vocab id
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="filterHasNoVocabId"
              checked={filters.noVocabId}
              onChange={(event) => { handleFilterChange(event, 'noVocabId') }}
              disabled={isLoading}
            />
            <label className="form-check-label" htmlFor="filterHasNoVocabId">
              has no vocab id
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="filterNoAudio"
              checked={filters.noAudio}
              onChange={(event) => { handleFilterChange(event, 'noAudio') }}
              disabled={isLoading}
            />
            <label className="form-check-label" htmlFor="filterNoAudio">
              no audio
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="filterNeedsImageQA"
              checked={filters.needsImageQA}
              onChange={(event) => { handleFilterChange(event, 'needsImageQA') }}
              disabled={isLoading}
            />
            <label className="form-check-label" htmlFor="filterNeedsImageQA">
            needs image QA
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="filterNeedsAudioQA"
              checked={filters.needsAudioQA}
              onChange={(event) => { handleFilterChange(event, 'needsAudioQA') }}
              disabled={isLoading}
            />
            <label className="form-check-label" htmlFor="filterNeedsAudioQA">
            needs audio QA
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="filterNoImageSituation"
              checked={filters.noImageSituation}
              onChange={(event) => { handleFilterChange(event, 'noImageSituation') }}
              disabled={isLoading}
            />
            <label className="form-check-label" htmlFor="filterNoImageSituation">
            no image situation
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="filterNoImageDescription"
              checked={filters.noImageDescription}
              onChange={(event) => { handleFilterChange(event, 'noImageDescription') }}
              disabled={isLoading}
            />
            <label className="form-check-label" htmlFor="filterNoImageDescription">
            no image description
            </label>
          </div>
        </div>

        {/*
        <div className="col-md-3">
          <input type="text" className="form-control" id="searchString" value={filterSearchString} onChange={handleSearchStringChange} placeholder="search" />
        </div>
        */}

        <div className="col-md-3">
          {[
            ['fetch_and_upload_image', 'Fetch images'],
            ['fetch_breakdown', 'Fetch breakdowns'],
            ['fetch_image_situation', 'Fetch image situations'],
            ['fetch_image_description', 'Fetch image descriptions'],
          ].map(([key, label]) => (
            <div key={key}>
              <button type="button" className={`ms-2 btn btn-outline-secondary ${isLoading || Object.keys(activeFetchActions).length ? 'disabled' : ''}`} onClick={(event) => { void handleFetchButtonClick(event, key as keyof AdminReadingActions) }}>{label}</button>
              {activeFetchActions[key as keyof AdminReadingActions] && <>{' '} Fetching {bulkFetchNum}/{filteredReadings.length}</>}
            </div>
          ))}
        </div>
      </div>

      <br/>
      <p>Showing {filteredReadings.length} readings</p>

      <table className="table">
        <thead>
          <tr>
            <th>Level</th>
            <th>Grade</th>
            <th>Kanji</th>
            <th>Reading</th>
            <th>Vocab</th>
            <th></th>
            <th>Sentence</th>
            <th>Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && 'loading...'}
          {!isLoading && filteredReadings.map((reading: AdminReading, index: number) =>
            <tr key={reading.id} className={bulkFetchNum && (bulkFetchNum - 1) === index ? 'table-warning' : ''}>
              <td>{reading.level}</td>
              <td>{reading.grade}</td>
              <td>
                <Link to={`/admin/kanji/${reading.kanji_id}`} target="_blank" className="link-unstyled">{reading.kanji}</Link>
              </td>
              <td style={{ whiteSpace: 'nowrap' }}>
                {reading.reading}
              </td>
              <td style={{ whiteSpace: 'nowrap' }}>
                <Link to={`/admin/vocabs/${reading.vocab_id}`}>{reading.vocab_id}</Link>
              </td>
              <td>
                {reading.flashcard_material.vocab} ({reading.flashcard_material.vocab_hiragana}): {reading.flashcard_material.vocab_meaning}
              </td>
              <td width="20%">
                {reading.flashcard_material.sentence}<br/>
                {reading.flashcard_material.sentence_meaning}
              </td>
              <td>
                {!!reading.flashcard_material.image_url && (
                  <img width="200px" src={reading.flashcard_material.image_url} />
                )}
              </td>
              <td>
                <Link to={`/admin/readings/${reading.id}?filters=${activeFilters().join('+')}`} className="btn btn-outline-secondary btn-sm" target="_blank">View</Link>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ReadingsIndex;
