export type Mastery = 'untrained' | 'learning' | 'intermediate' | 'mastered';

export enum Course {
  Kanji = 'kanji',
  Vocab = 'vocab',
}

export type ReadingGrade = 'elementary' | 'middle' | 'high'

export interface SentenceBreakdown {
  breakdown: SentenceBreakdownPart[]
}

export interface SentenceBreakdownPart {
  word: string
  pronunciation: string
  meaning: string
  vocab?: boolean // indicates whether this breakdown is the vocab of the reading
}

export interface FlashcardMaterial {
  kanji_id?: number
  kanji?: string
  reading_id?: number
  reading?: string
  grade?: ReadingGrade
  furigana: 'inline' | 'none'
  vocab: string
  vocab_hiragana: string
  vocab_meaning: string
  sentence: string
  sentence_breakdown?: SentenceBreakdown
  sentence_meaning: string
  image_url: string
  audio_url: string
  is_image_approved: boolean
  is_audio_approved: boolean
}

export interface Flashcard {
  flashcard_id: number
  course: Course
  level: number
  mastery_level: Mastery
  interval: number
  attempts: number
  ease_factor: number
  repetitions: number
  last_reviewed: string
  next_review: string
  material: FlashcardMaterial
}

export interface Kanji {
  id: number
  level: number
  character: string
  on_readings: KanjiReading[]
  kun_readings: KanjiReading[]
  vocabs: KanjiVocab[]
}

export interface KanjiReading {
  type: 'on' | 'kun'
  reading: string
  level: number
  grade: ReadingGrade
}

export interface KanjiVocab {
  vocab: string
  vocab_hiragana: string
  vocab_meaning: string
  level: number
  grade: ReadingGrade
}

export interface Stats {
  levels: StatsLevel[]
  review_forecast: Record<number, number>
}

export interface StatsLevel {
  level: number
  kanji: Stat
  vocab: Stat
}

export interface Stat {
  untrained: number
  learning: number
  intermediate: number
  mastered: number
}

export interface SentenceResponseRaw {
  sentence?: string
  japanese?: string
  japanese_sentence?: string
  breakdown?: string
  word_breakdown?: string
  wordBreakdown?: string
  meaning?: string
  english_meaning?: string
  english?: string
  english_translation?: string
  englishMeaning?: string
}

export interface UserProfile {
  email: string
  name: string
  level: Record<Course, number>
  subscription_status: 'inactive' | 'active'
  settings: UserSettings
}

export interface UserSettings {
  keyboard_shortcuts?: boolean
  audio_autoplay?: boolean // kanji course
  vocab_prompt?: 'audio' | 'text' | 'audio-text' // on flashcard front
  vocab_audio_autoplay?: boolean
  vocab_hide_text?: boolean // on flashcard back
}

export type KanjiMastery = Record<number, Record<string, Mastery>>

/* Admin Types */

export interface AdminReading {
  id: number
  kanji_id: number
  grade: string
  reading: string
  meaning: string
  vocab_id: number | null
  created_at: string
  updated_at: string
  kanji: string
  level: number
  image_tmp: string
  image_url: string
  audio_url: string
  flashcard_material: FlashcardMaterial
  is_image_approved: boolean
  is_audio_approved: boolean
  image_situation: string
  image_description: string
}

export interface AdminReadingFilters {
  noUnderline: boolean
  noSentence: boolean
  noImage: boolean
  hasVocabId: boolean
  noVocabId: boolean
  noAudio: boolean
  needsImageQA: boolean
  needsAudioQA: boolean
  noBreakdown: boolean
  noImageSituation: boolean
  noImageDescription: boolean
}

export interface AdminReadingActions {
  fetch_image?: boolean
  upload_image?: boolean
  fetch_and_upload_image?: boolean
  fetch_breakdown?: boolean
  fetch_image_situation?: boolean
  fetch_image_description?: boolean
}

export interface AdminKanji extends Kanji {
}

export interface AdminVocab {
  id: number
  sentence_id: number
  kanji: string
  reading: string
  definition: string
  created_at: string
  updated_at: string
  level: number
  flashcard_material: FlashcardMaterial
  is_image_approved: boolean
  is_audio_approved: boolean
}

export interface AdminVocabFilters {
  noUnderline: boolean
  noImage: boolean
  needsImageQA: boolean
  noBreakdown: boolean
}

export interface User {
  id: number
  email: string
  name: string
  provider: string
  timezone: string
  created_at: Date
  level: { kanji?: number }
  flashcard_count: number
  subscription_status: 'active' | 'inactive'
}
