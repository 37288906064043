import React from 'react';
import type { Flashcard, SentenceBreakdownPart } from '../types';
import {
  validBreakdownPart,
  highlightKana,
} from '../common';

interface Props {
  flashcard: Flashcard
  flipped: boolean
}

const KanaCardContent: React.FC<Props> = ({
  flashcard,
  flipped,
}) => {
  if (!flashcard.material.kana) {
    return 'Invalid card';
  }

  return (
    <>
      <h3 className="text-center biz-udpmincho-regular">
        <span dangerouslySetInnerHTML={{ __html: highlightKana(flashcard.material.kana, flashcard.material.vocab_hiragana) }} />
      </h3>

      <p className="text-center" style={{ visibility: flipped ? 'visible' : 'hidden' }}>{flashcard.material.vocab_meaning}</p>
      <br/>

      {flashcard.material.sentence_breakdown && (
        <p className={'sentence-breakdown'} style={{ visibility: flipped ? 'visible' : 'hidden' }}>
          <ul>
            {flashcard.material.sentence_breakdown.breakdown.filter((bp: SentenceBreakdownPart) => validBreakdownPart(bp)).map((bd: SentenceBreakdownPart, i: number) => (
              <li key={i}><span className="sentence-breakdown-word-pronunciation">{bd.word} ({bd.pronunciation})</span>: {bd.meaning}</li>
            ))}
          </ul>
        </p>
      )}
    </>
  );
};

export default KanaCardContent;
