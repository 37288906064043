import type { AdminVocab, AdminReading } from './types';
import { formatMaterialSentence } from './common';

export const getFilteredVocabs = (vocabs: AdminVocab[], filters: string[]): AdminVocab[] => {
  let _filteredVocabs = vocabs;
  if (filters.includes('noUnderline')) {
    _filteredVocabs = _filteredVocabs.filter((vocab: AdminVocab) => {
      const sentenceText = formatMaterialSentence(vocab.flashcard_material, vocab.level, false);
      return !sentenceText.includes('word-highlight');
    });
  }
  if (filters.includes('noBreakdown')) {
    _filteredVocabs = _filteredVocabs.filter((vocab: AdminVocab) => !vocab.flashcard_material.sentence_breakdown);
  }
  if (filters.includes('noImage')) {
    _filteredVocabs = _filteredVocabs.filter((vocab: AdminVocab) => !vocab.flashcard_material.image_url);
  }
  if (filters.includes('needsImageQA')) {
    _filteredVocabs = _filteredVocabs.filter((vocab: AdminVocab) => vocab.flashcard_material.image_url && !vocab.is_image_approved);
  }
  return _filteredVocabs;
};

export const getFilteredReadings = (readings: AdminReading[], filters: string[]): AdminReading[] => {
  let _filteredReadings = readings;
  if (filters.includes('noUnderline')) {
    _filteredReadings = _filteredReadings.filter((reading: AdminReading) => {
      const sentenceText = formatMaterialSentence(reading.flashcard_material, reading.level, false);
      return !sentenceText.includes('word-highlight');
    });
  }
  if (filters.includes('noSentence')) {
    _filteredReadings = _filteredReadings.filter((reading: AdminReading) => !reading.flashcard_material.sentence);
  }
  if (filters.includes('noBreakdown')) {
    _filteredReadings = _filteredReadings.filter((reading: AdminReading) => !reading.flashcard_material.sentence_breakdown);
  }
  if (filters.includes('noImage')) {
    _filteredReadings = _filteredReadings.filter((reading: AdminReading) => !reading.flashcard_material.image_url);
  }
  if (filters.includes('hasVocabId')) {
    _filteredReadings = _filteredReadings.filter((reading: AdminReading) => !!reading.vocab_id);
  }
  if (filters.includes('noVocabId')) {
    _filteredReadings = _filteredReadings.filter((reading: AdminReading) => !reading.vocab_id);
  }
  if (filters.includes('noAudio')) {
    _filteredReadings = _filteredReadings.filter((reading: AdminReading) => !reading.flashcard_material.audio_url);
  }
  if (filters.includes('needsAudioQA')) {
    _filteredReadings = _filteredReadings.filter((reading: AdminReading) => reading.flashcard_material.audio_url && !reading.is_audio_approved);
  }
  if (filters.includes('needsImageQA')) {
    _filteredReadings = _filteredReadings.filter((reading: AdminReading) => reading.flashcard_material.image_url && !reading.is_image_approved);
  }
  if (filters.includes('noImageSituation')) {
    _filteredReadings = _filteredReadings.filter((reading: AdminReading) => !reading.image_situation);
  }
  if (filters.includes('noImageDescription')) {
    _filteredReadings = _filteredReadings.filter((reading: AdminReading) => !reading.image_description);
  }
  return _filteredReadings;
};
