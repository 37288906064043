import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Layout from './Layout';
import Home from './Home';
import UserSettings from './UserSettings';
import Updates from './Updates';
import { Course } from '../types';

const queryClient = new QueryClient()

const App: React.FC = () => {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
      children: [
        { path: '/kana', element: <Home course={Course.Kana} /> },
        { path: '/kanji', element: <Home course={Course.Kanji} /> },
        { path: '/settings', element: <UserSettings /> },
        { path: '/updates', element: <Updates /> },
        { path: '/vocab', element: <Home course={Course.Vocab} /> },
      ],
    },
  ]);

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen />
    </QueryClientProvider>
  );
};

export default App;
