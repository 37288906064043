import React, { useEffect, useState, useRef } from 'react';
import type { MouseEvent } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useParams, Link, useNavigate, useSearchParams } from 'react-router-dom';
import type { AdminVocab, Flashcard, SentenceBreakdownPart } from '../../types';
import { Course } from '../../types';
import { getFlashcard } from '../../actions';
import FlashcardCard from '../FlashcardCard';
import type { AdminReading, AdminMaterial } from '../../types';
import MaterialDetails from './MaterialDetails';


const VocabDetails: React.FC = () => {
  /* Hooks */

  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [vocab, setVocab] = useState<AdminVocab | undefined>(undefined);
  const [vocabValues, setVocabValues] = useState<Record<string, string>>({});

  const setVocabValuesFromData = (material: AdminVocab): void => {
    setVocabValues({
      vocabKanji: material.kanji || '',
      vocabReading: material.reading || '',
      vocabDefinition: material.definition || '',
      sentenceText: material.sentence.text || '',
      sentenceTranslation: material.sentence.translation || '',
      sentenceBreakdown: material.sentence.breakdown || '',
      sentenceAudioSpec: material.sentence.audio_spec || '',
    });
  };

  const saveData = {
    vocab: {
      kanji: vocabValues.vocabKanji,
      reading: vocabValues.vocabReading,
      definition: vocabValues.vocabDefinition,
    },
    sentence: {
      text: vocabValues.sentenceText || undefined,
      translation: vocabValues.sentenceTranslation || undefined,
      breakdown: vocabValues.sentenceBreakdown || undefined,
      audio_spec: vocabValues.sentenceAudioSpec || undefined,
    },
  };

  const handleVocabKanjiChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setVocabValues((prev) => ({ ...prev, vocabKanji: event.target.value }));
  };

  const handleVocabReadingChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setVocabValues((prev) => ({ ...prev, vocabReading: event.target.value }));
  };

  const handleVocabDefinitionChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setVocabValues((prev) => ({ ...prev, vocabDefinition: event.target.value }));
  };

  const handleSentenceTextChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setVocabValues((prev) => ({ ...prev, sentenceText: event.target.value }));
  };

  const handleSentenceTranslationChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setVocabValues((prev) => ({ ...prev, sentenceTranslation: event.target.value }));
  };

  const handleSentenceBreakdownChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setVocabValues((prev) => ({ ...prev, sentenceBreakdown: event.target.value }));
  };

  const handleSentenceAudioSpecChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setVocabValues((prev) => ({ ...prev, sentenceAudioSpec: event.target.value }));
  };

  const handleSetBreakdownVocab = (index: number): void => {
    const newBreakdown = { ...JSON.parse(vocabValues.sentenceBreakdown) };
    newBreakdown.breakdown.forEach((bp: SentenceBreakdownPart, bpi: number) => {
      if (bpi === index) {
        newBreakdown.breakdown[bpi] = { ...bp, vocab: true };
      } else {
        newBreakdown.breakdown[bpi] = { word: bp.word, pronunciation: bp.pronunciation, meaning: bp.meaning };
      }
    });
    setVocabValues((prev) => ({ ...prev, sentenceBreakdown: JSON.stringify(newBreakdown) }));
  };

  const handleSetPhoneme = (index: number): void => {
    const items = JSON.parse(vocabValues.sentenceBreakdown).breakdown as SentenceBreakdownPart[];
    const foreward = items.slice(0, index).map((bp: SentenceBreakdownPart) => bp.word).join('');
    const afterward = items.slice(index + 1).map((bp: SentenceBreakdownPart) => bp.word).join('');
    setVocabValues((prev) => ({ ...prev, sentenceAudioSpec: `<speak>${foreward}<phoneme alphabet="x-amazon-yomigana" ph="${items[index].pronunciation}">${items[index].word}</phoneme>${afterward}</speak>` }));
  };

  return (
    <MaterialDetails
      course={Course.Vocab}
      materialType={'Vocab'}
      urlPath={'vocabs'}
      setMaterialForParent={setVocab as (material: AdminMaterial) => void}
      setMaterialValues={setVocabValuesFromData as (material: AdminMaterial) => void}
      materialSaveData={saveData}
      isUpdating={isUpdating}
      setIsUpdating={setIsUpdating}
    >
      {!!vocab && (
        <>
          <h2>{vocab.kanji} [{vocab.reading}]</h2>
          <table className="table table-borderless">
            <tbody>
              <tr>
                <th>ID</th>
                <td>{vocab.id}</td>
              </tr>
              <tr>
                <th>Level</th>
                <td>{vocab.level}</td>
              </tr>
              <tr>
                <th>Vocab Kanji</th>
                <td>
                  <input type="text" className="form-control" id="vocabKanji" disabled={isUpdating} value={vocabValues.vocabKanji} onChange={handleVocabKanjiChange} />
                </td>
              </tr>
              <tr>
                <th>Vocab Reading</th>
                <td>
                  <input type="text" className="form-control" id="vocabReading" disabled={isUpdating} value={vocabValues.vocabReading} onChange={handleVocabReadingChange} />
                </td>
              </tr>
              <tr>
                <th>Vocab Definition</th>
                <td>
                  <input type="text" className="form-control" id="vocabDefinition" disabled={isUpdating} value={vocabValues.vocabDefinition} onChange={handleVocabDefinitionChange} />
                </td>
              </tr>
              <tr>
                <th>Sentence ID</th>
                <td>
                  {vocab.sentence?.id}
                </td>
              </tr>
              <tr>
                <th>Sentence Text</th>
                <td>
                  <input type="text" className="form-control" id="sentenceText" disabled={isUpdating} value={vocabValues.sentenceText} onChange={handleSentenceTextChange} />
                </td>
              </tr>
              <tr>
                <th>Sentence Clean</th>
                <td>
                  {vocab.sentence?.clean_text}
                </td>
              </tr>
              <tr>
                <th>Sentence Translation</th>
                <td>
                  <input type="text" className="form-control" id="sentenceText" disabled={isUpdating} value={vocabValues.sentenceTranslation} onChange={handleSentenceTranslationChange} />
                </td>
              </tr>
              <tr>
                <th>Sentence Breakdown</th>
                <td>
                  <textarea
                    id="sentenceBreakdown"
                    className="form-control"
                    disabled={isUpdating}
                    value={vocabValues.sentenceBreakdown}
                    onChange={handleSentenceBreakdownChange}
                    rows={10}
                  />
                </td>
              </tr>
              <tr>
                <th>Sentence Audio Spec</th>
                <td>
                  <textarea
                    id="sentenceAudioSpec"
                    className="form-control"
                    disabled={isUpdating}
                    value={vocabValues.sentenceAudioSpec}
                    onChange={handleSentenceAudioSpecChange}
                    rows={10}
                  />
                  <p style={{ fontSize: '9px' }}><pre><code>{'<speak>\n<phoneme alphabet="x-amazon-yomigana" ph="あした">明日</phoneme>\n</speak>'}</code></pre></p>
                </td>
              </tr>
            </tbody>
          </table>

          {!!vocabValues.sentenceBreakdown && (
            <>
              <h3>Sentence Breakdown</h3>
              <table className="table">
                <tr>
                  <th>Word</th>
                  <th>Pronunciation</th>
                  <th>Meaning</th>
                  <th>Vocab</th>
                </tr>
                {JSON.parse(vocabValues.sentenceBreakdown).breakdown.map((bp: SentenceBreakdownPart, index: number) => (
                  <tr key={index}>
                    <td>{bp.word}</td>
                    <td>{bp.pronunciation}</td>
                    <td>{bp.meaning}</td>
                    <td>
                      {bp.vocab ? 'true' : <button type="button" style={{ padding: 0 }} className="btn btn-sm btn-outline-secondary" onClick={() => { handleSetBreakdownVocab(index) }}>Vocab</button>}
                      <button type="button" style={{ padding: 0 }} className="btn btn-sm btn-outline-secondary" onClick={() => { handleSetPhoneme(index) }}>Phoneme</button>
                    </td>
                  </tr>
                ))}
              </table>
            </>
          )}
        </>
      )}
    </MaterialDetails>
  );
};

export default VocabDetails;
