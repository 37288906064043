import React, { useState } from 'react';
import type { AdminKana, AdminMaterial } from '../../types';
import MaterialDetails from './MaterialDetails';
import { Course } from '../../types';

const KanaDetails: React.FC = () => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [kana, setKana] = useState<AdminKana>();
  const [kanaValues, setKanaValues] = useState<Record<string, string>>({});

  const setKanaValuesFromData = (material: AdminKana): void => {
    setKanaValues({
      vocabImageSituation: material.image_situation || '',
      vocabImageDescription: material.image_description || '',
      vocabAudioSpec: material.audio_spec || '',
    });
  };

  const saveData = {
    material: {
      grade: kanaValues.grade,
      vocab_id: Number(kanaValues.vocabId) || null,
    },
    vocab: {
      image_situation: kanaValues.vocabImageSituation || null,
      image_description: kanaValues.vocabImageDescription || null,
      audio_spec: kanaValues.vocabAudioSpec || null
    }
  };

  /* Handlers */
  const handleVocabImageSituationChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setKanaValues((prev) => ({ ...prev, vocabImageSituation: event.target.value }));
  };

  const handleVocabImageDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setKanaValues((prev) => ({ ...prev, vocabImageDescription: event.target.value }));
  };

  const handleVocabAudioSpecChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setKanaValues((prev) => ({ ...prev, vocabAudioSpec: event.target.value }));
  };

  return (
    <MaterialDetails
      course={Course.Kana}
      materialType={'Kana'}
      urlPath={'kanas'}
      setMaterialForParent={setKana as (material: AdminMaterial) => void}
      setMaterialValues={setKanaValuesFromData as (material: AdminMaterial) => void}
      materialSaveData={saveData}
      isUpdating={isUpdating}
      setIsUpdating={setIsUpdating}
    >
      {!!kana && (
        <>
          <h2>{kana.kana}</h2>
          <table className="table table-borderless">
            <tbody>
              <tr>
                <th>ID</th>
                <td>{kana.id}</td>
              </tr>

              <tr>
                <th>Level</th>
                <td>{kana.level}</td>
              </tr>

              <tr>
                <th>Vocab</th>
                <td>{kana.vocab?.reading} ({kana.vocab?.kanji})</td>
              </tr>

              <tr>
                <th>Image Situation</th>
                <td>
                  <textarea
                    id="kanaImageSituation"
                    className="form-control"
                    disabled={isUpdating}
                    value={kanaValues.vocabImageSituation}
                    onChange={handleVocabImageSituationChange}
                    rows={3}
                  />
                </td>
              </tr>
              <tr>
                <th>Image Description</th>
                <td>
                  <textarea
                    id="kanaImageDescription"
                    className="form-control"
                    disabled={isUpdating}
                    value={kanaValues.vocabImageDescription}
                    onChange={handleVocabImageDescriptionChange}
                    rows={5}
                  />
                </td>
              </tr>
              <tr>
                <th>Vocab Audio Spec</th>
                <td>
                  <textarea
                    id="vocabAudioSpec"
                    className="form-control"
                    disabled={isUpdating}
                    value={kanaValues.vocabAudioSpec}
                    onChange={handleVocabAudioSpecChange}
                    rows={10}
                  />
                  <p style={{ fontSize: '9px' }}><pre><code>{'<speak>\n<phoneme alphabet="x-amazon-yomigana" ph="あした">明日</phoneme>\n</speak>'}</code></pre></p>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </MaterialDetails>
  );
};

export default KanaDetails;
