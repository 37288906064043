import React from 'react';
import type { Stats, Course } from '../types';
import { formatCourseName } from '../common';

interface Props {
  course: Course
  stats?: Stats
}

const ReviewForecast: React.FC<Props> = ({ course, stats }) => {
  const reviewForecast = stats?.review_forecast ?? {};

  return (
    <>
      <h4 className="mt-4 mt-lg-0">{formatCourseName(course)} Review Forecast</h4>
      <table className="table table-sm">
        <thead>
          <tr>
            <th>Today</th>
            <th>Tomorrow</th>
            <th>+2 days</th>
            <th>+3 days</th>
            <th>+4 days</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{reviewForecast[0] || 0}</td>
            <td>{reviewForecast[1] || 0}</td>
            <td>{reviewForecast[2] || 0}</td>
            <td>{reviewForecast[3] || 0}</td>
            <td>{reviewForecast[4] || 0}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default ReviewForecast;
