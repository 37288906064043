import React from 'react';

const Updates: React.FC = () => {
  const timeAgo = (date: string): string => {
    const now = new Date();
    const past = new Date(date);
    const differenceInMs = now.getTime() - past.getTime();
    const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));

    if (differenceInDays === 0) {
      return 'Today';
    } else if (differenceInDays === 1) {
      return '1 day ago';
    } else {
      return `${differenceInDays} days ago`;
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-2">
        </div>
        <div className="col-md-8">
          <h1 className="text-center">What&apos;s new</h1>

          <dl className="row">
            <dt className="col-sm-2 fw-normal">{timeAgo('2025-01-12')}</dt>
            <dd className="col-sm-10">
              <p><strong>Vocab course beta</strong></p>
              <p>The Vocab course is now available in beta. The course teaches almost 10,000 total words in sentence-context, including all-hiragana/katakana vocabulary. Like the Kanji course, the content is divided into 9 levels. Unlike the Kanji course which is inherently a reading course, the Vocab course can be learned audio-only. You can customize the experience at <a href="/settings">Settings</a>.</p>
            </dd>
          </dl>

          <dl className="row">
            <dt className="col-sm-2 fw-normal">{timeAgo('2024-12-28')}</dt>
            <dd className="col-sm-10">
              <p><strong>Intermediate+ cards hide sentence context</strong></p>
              <p>Flashcards that reach Intermediate level become a little more challenging. These cards are initially presented without sentence context, challenging you to recall the word in isolation. If you fail the card, the review interval will reset to 0 hours, but the card&apos;s level won&apos;t go back to &quot;Learning&quot;; it will stay &quot;Intermediate&quot;.</p>
              <p>An &quot;Intermediate&quot; or &quot;Mastered&quot; card won&apos;t be downgraded to &quot;Learning&quot;, but a &quot;Mastered&quot; card can get downgraded to &quot;Intermediate&quot;.</p>

              <p><strong>&quot;I already know it&quot; button in lessons</strong></p>
              <p>This introduces two changes to lessons (new cards).</p>
              <p>(1) New cards now initially appear &quot;unflipped&quot; (ie. answers hidden) like review cards. This gives you a chance to evaluate whether you already know the content.</p>
              <p>(2) Once a new card is flipped, you now have two buttons: &quot;I already know it&quot; and &quot;Next&quot;. Pressing &quot;I already know it&quot; starts the card with an interval of 29 days, skipping the usual ramp-up schedule intended for new material.</p>
            </dd>
          </dl>

        </div>
      </div>
    </div>
  );
};

export default Updates;
