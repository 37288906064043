import React from 'react';
import type { MouseEvent } from 'react';
import type { Flashcard, UserSettings, SentenceBreakdownPart } from '../types';
import { Course } from '../types';
import { pluralize, formatMaterialSentence, getHasAudio, validBreakdownPart } from '../common';

interface Props {
  flashcard: Flashcard
  userSubscribed: boolean
  flipped: boolean
  loading: boolean
  onFlip: () => void
  onNext: (grade: number) => void
  hideButtons?: boolean
  last?: boolean
  audioLoaded?: boolean
  audioPlaying?: boolean
  playAudio?: () => void
  settings: UserSettings
}

const FlashcardCard: React.FC<Props> = ({
  flashcard,
  userSubscribed,
  flipped,
  loading,
  onFlip,
  onNext,
  hideButtons,
  last,
  audioLoaded,
  audioPlaying,
  playAudio,
  settings,
}) => {
  const handleFail = (): void => {
    onNext(0);
  };

  const handleHardSuccess = (): void => {
    onNext(3);
  };

  const handleEasySuccess = (): void => {
    onNext(5);
  };

  const handleAlreadyKnown = (): void => {
    onNext(10);
  };

  const handleClickPlayAudio = (event: MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault();
    if (playAudio) {
      playAudio();
    }
  };

  const copySentenceToClipboard = (): void => {
    void navigator.clipboard.writeText(flashcard.material?.sentence.replace(/\[[^\]]*\]/g, '').replace(/[\s]/g, ''));
  };

  if (loading) {
    // return null;
  }

  const material = flashcard.material;

  if (!material?.sentence || !material?.sentence_meaning) {
    return 'Invalid card';
  }
  if (flashcard.course === Course.Kanji && material?.furigana === 'none' && !material?.sentence_breakdown) {
    return 'Invalid card';
  }

  const course = flashcard.course;

  const isCardNew = flashcard.attempts === 0;

  const hasAudio = !last && getHasAudio(course, flipped, settings, material);

  const hardMode = !flipped && (flashcard.mastery_level === 'intermediate' || flashcard.mastery_level === 'mastered');

  const sentenceClasses = ['flashcard-sentence']
  sentenceClasses.push(`flashcard-${flipped ? 'back' : 'front'}`);
  if (course === Course.Kanji) {
    sentenceClasses.push('flashcard-kanji');
    if (hardMode) {
      sentenceClasses.push('flashcard-sentence-hard');
    }
  } else if (course === Course.Vocab) {
    sentenceClasses.push('flashcard-vocab');
    sentenceClasses.push(`flashcard-vocab-prompt-${settings.vocab_prompt}`);
    if (settings.vocab_prompt === 'audio' && settings.vocab_hide_text) {
      sentenceClasses.push('flashcard-vocab-hide-text');
    }
  }

  return (
    <>
      <h3 className="biz-udpmincho-regular">
        <span className={sentenceClasses.join(' ')}>
          <span dangerouslySetInnerHTML={{ __html: formatMaterialSentence(material, flashcard.level, flipped) }} />
        </span>

        {flipped && (
        <><a style={{ fontSize: '18px' }} href="#" className="text-very-muted" onClick={copySentenceToClipboard}><i className="bi bi-copy"></i></a>{' '}</>
        )}

        {hasAudio && (!audioLoaded || audioPlaying) && (
        <div className="spinner-border spinner-border-sm" style={{ borderWidth: '1px', borderColor: '#ccc', borderRightColor: 'transparent' }} role="status"></div>
        )}

        {hasAudio && audioLoaded && !audioPlaying && (
        <a style={{ fontSize: '18px' }} href="#" className="text-very-muted" onClick={handleClickPlayAudio}><i className="bi bi-volume-up"></i></a>
        )}
      </h3>

      <p style={{ visibility: flipped ? 'visible' : 'hidden' }}>{material.sentence_meaning}</p>

      {material.sentence_breakdown && (
        <p className={`sentence-breakdown ${course === Course.Vocab && settings.vocab_prompt === 'audio' && settings.vocab_hide_text ? 'vocab-hide-text' : ''}`} style={{ visibility: flipped ? 'visible' : 'hidden' }}>
          <ul>
            {material.sentence_breakdown.breakdown.filter((bp: SentenceBreakdownPart) => validBreakdownPart(bp)).map((bd: SentenceBreakdownPart, i: number) => (
              <li key={i}><span className="sentence-breakdown-word-pronunciation">{bd.word} ({bd.pronunciation})</span>: {bd.meaning}</li>
            ))}
          </ul>
        </p>
      )}

      {!isCardNew && (
        <>
          <span className={`${(hideButtons ?? flipped) && 'invisible'}`}>
            <button type="button" className="btn btn-primary" onClick={onFlip}>Flip</button>
          </span>
          <span className={`${(hideButtons ?? !flipped) && 'invisible'}`}>
            <button type="button" className="btn btn-danger me-4 me-sm-2" onClick={handleFail}><span className="d-block d-xl-none">Wrong</span><span className="d-none d-xl-block">I didn&apos;t get it</span></button>
            <button type="button" className="btn btn-warning me-4 me-sm-2" onClick={handleHardSuccess}><span className="d-block d-xl-none">Hard</span><span className="d-none d-xl-block">I got it (hard)</span></button>
            <button type="button" className="btn btn-success" onClick={handleEasySuccess}><span className="d-block d-xl-none">Easy</span><span className="d-none d-xl-block">I got it (easy)</span></button>
          </span>
        </>
      )}

      {isCardNew && (
        <>
          <span className={`${(hideButtons ?? flipped) && 'invisible'}`}>
            <button type="button" className="btn btn-primary" onClick={onFlip}>Flip</button>
          </span>
          <span className={`${(hideButtons ?? !flipped) && 'invisible'}`}>
            <button type="button" className="btn btn-outline-secondary me-2" disabled={!userSubscribed} onClick={handleAlreadyKnown}>
              {!userSubscribed && <><i className="bi bi-lock"></i>{' '}</>}
              I already know this
            </button>
            <button type="button" className="btn btn-primary" onClick={handleFail}>Next</button>
          </span>
        </>
      )}

      {last && (
        <div>
          Grade:{' '}
          <span style={{ border: flashcard.mastery_level === 'learning' ? '1px solid #ccc' : 'none' }} className={`badge text-${flashcard.mastery_level}`}>{flashcard.mastery_level}</span>{' '}
          <span className="mb-0"><small className="text-very-muted">You will train this card again in {flashcard.interval >= 24 ? pluralize('day', Math.round(flashcard.interval / 24)) : pluralize('hour', flashcard.interval)}.</small></span>
        </div>
      )}
    </>
  );
};

export default FlashcardCard;
