import React from 'react'
import type { Flashcard, Course, UserSettings } from '../types';
import { pluralize, formatCourseName } from '../common';
import FlashcardCard from './FlashcardCard';
import SubscribeButton from './SubscribeButton';

interface Props {
  course: Course
  flashcard?: Flashcard | null
  cardCounter: number
  lastFlashcard?: Flashcard
  started: boolean
  flipped: boolean
  isCardNew: boolean
  loading: boolean
  onFlip: () => void
  onNext: (grade: number) => void
  onStartReview: () => void
  onStartNewBatch: () => void
  justFinishedNewBatch: boolean
  userNextReviewInMinutes: number
  reviewCount: number
  freezeReviewCount: number
  userSubscribed: boolean
  audioLoaded: boolean
  audioPlaying: boolean
  playAudio: () => void
  settings: UserSettings
}

enum FlashcardState {
  Arrival = 'ARRIVAL',
  Reviews = 'REVIEWS',
  Lessons = 'LESSONS',
  Rest = 'REST',
  Started = 'STARTED',
}

const FlashcardPane: React.FC<Props> = ({
  course,
  flashcard,
  cardCounter,
  lastFlashcard,
  started,
  flipped,
  isCardNew,
  loading,
  onFlip,
  onNext,
  onStartReview,
  onStartNewBatch,
  justFinishedNewBatch,
  userNextReviewInMinutes,
  reviewCount,
  freezeReviewCount,
  userSubscribed,
  audioLoaded,
  audioPlaying,
  playAudio,
  settings,
}) => {
  const nextReviewPreview = `Your next reviews will be available in ${userNextReviewInMinutes < 60 ? pluralize('minute', Math.round(userNextReviewInMinutes)) : userNextReviewInMinutes < 60 * 24 ? pluralize('hour', Math.round(userNextReviewInMinutes / 60)) : pluralize('day', Math.round(userNextReviewInMinutes / (60 * 24)))}.`

  let state: undefined | FlashcardState;
  if (flashcard === null) {
    state = FlashcardState.Rest;
  } else if (!flashcard) {
    // NOOP
  } else if (started) {
    state = FlashcardState.Started;
  } else if (!isCardNew) {
    state = FlashcardState.Reviews;
  } else if (isCardNew && !justFinishedNewBatch && userNextReviewInMinutes === -1) {
    state = FlashcardState.Arrival;
  } else if (isCardNew) {
    // TODO fix case where it flashes this status after finishing lessons, before loading the next card to review.
    // have to also handle the case where all cards in the batch were skipped, which should show a new batch of lessons.
    state = FlashcardState.Lessons;
  }

  return (
    <>
      <div>
        {state !== FlashcardState.Started && (
          <h4>{formatCourseName(course)} Flashcards</h4>
        )}

        {state === FlashcardState.Rest && (
          <div className="card text-center">
            <div className="card-body">
              <h5 className="card-title">You&apos;re caught up on reviews and lessons.</h5>
              <p>{nextReviewPreview}</p>
              {!userSubscribed && (
                <p>
                  <SubscribeButton icon={'unlock'} label={'Subscribe to unlock higher levels'} large={true} />
                </p>
              )}
            </div>
            <img className="card-img-bottom" src="https://assets.kanji.ski/rest-1.webp" style={{ objectFit: 'cover' }} />
          </div>
        )}
        {state === FlashcardState.Arrival && (
          <div className="card text-center">
            <div className="card-body">
              <h5 className="card-title">You made it!</h5>
              <p>Let&apos;s get started</p>
              <button type="button" className="btn btn-lg btn-primary my-2" onClick={onStartNewBatch}>Learn 10 new cards</button>
            </div>
            <img className="card-img-bottom" src="https://assets.kanji.ski/start-1.webp" style={{ objectFit: 'cover' }} />
          </div>
        )}
        {state === FlashcardState.Lessons && (
          <div className="card text-center">
            <div className="card-body">
              <h5 className="card-title">You&apos;re all caught up on reviews.</h5>
              <p>{nextReviewPreview}</p>
              <button type="button" className="btn btn-lg btn-primary my-2" onClick={onStartNewBatch}>Learn 10 new cards</button>
            </div>
            <img className="card-img-bottom" src="https://assets.kanji.ski/lessons-time-1.webp" style={{ objectFit: 'cover' }} />
          </div>
        )}
        {state === FlashcardState.Reviews && (
          <div className="card text-center">
            <div className="card-body">
              <h5 className="card-title">{justFinishedNewBatch ? 'OK, now review the cards you just learned' : `You have ${pluralize('card', reviewCount)} ready for review.`}</h5>
              <button type="button" className="btn btn-lg btn-primary my-2" onClick={onStartReview}>Send it!</button>
            </div>
            <img className="card-img-bottom" src="https://assets.kanji.ski/review-time-1.webp" style={{ objectFit: 'cover' }} />
          </div>
        )}
        {state === FlashcardState.Started && (
          <>
            <h4>
              {isCardNew ? <span className="text-danger">New Card {cardCounter}/10</span> : <>Review {cardCounter}/{freezeReviewCount}</>}
            </h4>
            <div className="card">
              <div className="card-body">
                {!!flashcard && (
                  <FlashcardCard
                    flashcard={flashcard}
                    userSubscribed={userSubscribed}
                    flipped={flipped}
                    loading={loading}
                    onFlip={onFlip}
                    onNext={onNext}
                    audioLoaded={audioLoaded}
                    audioPlaying={audioPlaying}
                    playAudio={playAudio}
                    settings={settings}
                  />
                )}
              </div>
              {flashcard?.material?.image_url && flipped && (
                <img width="100%" src={flashcard.material?.image_url} />
              )}
            </div>
          </>
        )}
      </div>

      {started && !!lastFlashcard && !isCardNew && (
        <>
          <h4 className="mt-4">Previous Card</h4>
          <div className="card">
            <div className="card-body">
              <FlashcardCard
                flashcard={lastFlashcard}
                userSubscribed={userSubscribed}
                last={true}
                flipped={true}
                loading={false}
                onFlip={() => {}}
                onNext={() => {}}
                hideButtons={true}
                settings={settings}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default FlashcardPane;
