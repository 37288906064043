import React from 'react';
import { Outlet } from 'react-router-dom';

const AdminLayout: React.FC = () => {
  return (
    <div>
      {/* <!-- insert nav here --> */}
      <div className="container">
        <header className="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom">
          <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
            <span style={{ fontSize: '28px' }}>🎿</span>
            <span className="fs-4 ms-2">Admin</span>
          </a>

          <ul className="nav nav-pills">
            <li className="nav-item"><a href="/admin/users" className="nav-link">Users</a></li>
            <li className="nav-item"><a href="/admin/readings" className="nav-link">Readings</a></li>
            <li className="nav-item"><a href="/admin/kanji" className="nav-link">Kanji</a></li>
            <li className="nav-item"><a href="/admin/vocabs" className="nav-link">Vocab</a></li>
          </ul>
        </header>
      </div>

      <Outlet />
    </div>
  );
};

export default AdminLayout;
