import React from 'react';
import type { MouseEvent } from 'react';
import type { Flashcard, UserSettings } from '../types';
import { Course } from '../types';
import { pluralize, getHasAudio } from '../common';
import KanjiCardContent from './KanjiCardContent';
import VocabCardContent from './VocabCardContent';
import KanaCardContent from './KanaCardContent';

interface Props {
  flashcard: Flashcard
  userSubscribed: boolean
  flipped: boolean
  loading: boolean
  onFlip: () => void
  onNext: (grade: number) => void
  hideButtons?: boolean
  last?: boolean
  audioLoaded?: boolean
  audioPlaying?: boolean
  playAudio?: () => void
  settings: UserSettings
}

const FlashcardCard: React.FC<Props> = ({
  flashcard,
  userSubscribed,
  flipped,
  loading,
  onFlip,
  onNext,
  hideButtons,
  last,
  audioLoaded,
  audioPlaying,
  playAudio,
  settings,
}) => {
  const handleFail = (): void => {
    onNext(0);
  };

  const handleHardSuccess = (): void => {
    onNext(3);
  };

  const handleEasySuccess = (): void => {
    onNext(5);
  };

  const handleAlreadyKnown = (): void => {
    onNext(10);
  };

  const handleClickPlayAudio = (event: MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault();
    if (playAudio) {
      playAudio();
    }
  };

  if (loading) {
    // return null;
  }

  const material = flashcard.material;
  const course = flashcard.course;
  const isCardNew = flashcard.attempts === 0;
  const hasAudio = !last && getHasAudio(course, flipped, settings, material);

  return (
    <div className={`flashcard-${flipped ? 'back' : 'front'}`}>
      {hasAudio && (
        <>
          {audioLoaded && !audioPlaying
            ? <a style={{ fontSize: '18px' }} href="#" className="text-very-muted" onClick={handleClickPlayAudio}><i className="bi bi-volume-up"></i></a>
            : <div className="spinner-border spinner-border-sm" style={{ borderWidth: '1px', borderColor: '#ccc', borderRightColor: 'transparent' }} role="status"></div>
          }
        </>
      )}

      {course === Course.Kanji
        ? <KanjiCardContent flashcard={flashcard} flipped={flipped} />
        : course === Course.Vocab
          ? <VocabCardContent flashcard={flashcard} flipped={flipped} settings={settings} />
          : course === Course.Kana
            ? <KanaCardContent flashcard={flashcard} flipped={flipped} />
            : <></>
      }

      {!isCardNew && (
        <>
          <span className={`${(hideButtons ?? flipped) && 'invisible'}`}>
            <button type="button" className="btn btn-primary" onClick={onFlip}>Flip</button>
          </span>
          <span className={`${(hideButtons ?? !flipped) && 'invisible'}`}>
            <button type="button" className="btn btn-danger me-4 me-sm-2" onClick={handleFail}><span className="d-block d-xl-none">Wrong</span><span className="d-none d-xl-block">I didn&apos;t get it</span></button>
            <button type="button" className="btn btn-warning me-4 me-sm-2" onClick={handleHardSuccess}><span className="d-block d-xl-none">Hard</span><span className="d-none d-xl-block">I got it (hard)</span></button>
            <button type="button" className="btn btn-success" onClick={handleEasySuccess}><span className="d-block d-xl-none">Easy</span><span className="d-none d-xl-block">I got it (easy)</span></button>
          </span>
        </>
      )}

      {isCardNew && (
        <>
          <span className={`${(hideButtons ?? flipped) && 'invisible'}`}>
            <button type="button" className="btn btn-primary" onClick={onFlip}>Flip</button>
          </span>
          <span className={`${(hideButtons ?? !flipped) && 'invisible'}`}>
            <button type="button" className="btn btn-outline-secondary me-2" disabled={!userSubscribed} onClick={handleAlreadyKnown}>
              {!userSubscribed && <><i className="bi bi-lock"></i>{' '}</>}
              I already know this
            </button>
            <button type="button" className="btn btn-primary" onClick={handleFail}>Next</button>
          </span>
        </>
      )}

      {last && (
        <div>
          Grade:{' '}
          <span style={{ border: flashcard.mastery_level === 'learning' ? '1px solid #ccc' : 'none' }} className={`badge text-${flashcard.mastery_level}`}>{flashcard.mastery_level}</span>{' '}
          <span className="mb-0"><small className="text-very-muted">You will train this card again in {flashcard.interval >= 24 ? pluralize('day', Math.round(flashcard.interval / 24)) : pluralize('hour', flashcard.interval)}.</small></span>
        </div>
      )}
    </div>
  );
};

export default FlashcardCard;
